import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { MonitoringMethod, UserRole } from 'core/constants';
import {
  DashboardList,
  ProfileOrganisationResponse,
  SurveyResponse,
  UserLicensesResponse,
  UserProfileResponse,
} from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { ActionEvent, UpdatedProfilePayload, UpdatedUserPayload } from 'utils/events/types';

class SessionUser {
  uuid: string;

  @observable role: UserRole;

  email: string;

  @observable name: string;

  @observable phoneNumber: string | null;

  monitoringMethod: MonitoringMethod;

  organisation: ProfileOrganisationResponse;

  licenses: UserLicensesResponse;

  @observable twoFactorAuthEnabled: boolean;

  @observable twoFactorAuthAllowed: boolean;

  @observable ssoUser: boolean;

  @observable showSurvey: boolean;

  @observable survey: SurveyResponse;

  @observable dashboards: DashboardList | null;

  @observable appCues: boolean;

  @observable helpScout: boolean;

  isProtectorActive: boolean;

  isWorkflowsActive: boolean;

  readOnly: boolean;

  constructor(data: UserProfileResponse) {
    makeObservable(this);
    this.uuid = data.uuid;
    this.name = data.name;
    this.role = data.role;
    this.phoneNumber = data.phoneNumber;
    this.email = data.email;
    this.monitoringMethod = data.monitoringMethod;
    this.organisation = data.organisation;
    this.twoFactorAuthEnabled = data.twoFactorAuthEnabled;
    this.twoFactorAuthAllowed = data.twoFactorAuthAllowed;
    this.ssoUser = data.ssoUser;
    this.isProtectorActive = data.isProtectorActive;
    this.isWorkflowsActive = data.isWorkflowsActive;
    this.licenses = data.licenses;
    this.showSurvey = data.showSurvey;
    this.survey = data.survey;
    this.dashboards = data.dashboards;
    this.appCues = data.appCues;
    this.helpScout = data.helpScout;
    this.readOnly = data.readOnly;

    eventBus.register(this);
  }

  @action
  enableTwoFactorAuth(): void {
    this.twoFactorAuthEnabled = true;
  }

  @action
  disableTwoFactorAuth(): void {
    this.twoFactorAuthEnabled = false;
  }

  @subscribe(EventType.UpdatedUser)
  @subscribe(EventType.UpdatedProfile)
  @action
  updatedUser({ payload }: ActionEvent<UpdatedProfilePayload | UpdatedUserPayload>): void {
    if (payload.uuid !== this.uuid) {
      return;
    }

    this.name = payload.name;
    this.phoneNumber = payload.phoneNumber;
    this.role = payload.role;
  }
}

export default SessionUser;
