import React from 'react';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { LocationProperties, QuestionType } from 'stores/TemplateDetails/types';

import { formatFullDateWithDots } from 'utils/formatters/time';

import BooleanAnswer from './components/FormTab/BooleanAnswer';
import TextAnswer from './components/FormTab/TextAnswer';
import MultiChoiceAnswer from './components/FormTab/MultiChoiceAnswer';
import SignatureAnswer from './components/FormTab/SignatureAnswer';
import { AnswerText, EmptyAnswerText } from './components/FormTab/styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isLocationAnswerQuestionType = (obj: any): obj is LocationProperties =>
  typeof obj === 'object' && obj.address !== undefined;

export function getAnswerComponent(
  type: string,
  answer: AnswerDetails,
  questionProperties: QuestionType
): React.ReactNode {
  switch (type) {
    case 'boolean':
      return <BooleanAnswer answer={answer} />;
    case 'string':
      return <TextAnswer answer={answer} />;
    case 'date':
      return answer.answer ? (
        <AnswerText>{formatFullDateWithDots(answer.answer as string)}</AnswerText>
      ) : (
        <EmptyAnswerText>Not answered</EmptyAnswerText>
      );
    case 'location':
      return answer.answer && isLocationAnswerQuestionType(answer.answer) ? (
        <AnswerText>{answer.answer.address as string}</AnswerText>
      ) : (
        <EmptyAnswerText>Not answered</EmptyAnswerText>
      );
    case 'multi':
    case 'array':
      return <MultiChoiceAnswer answers={answer?.answer as string[]} />;
    case 'signature':
      return <SignatureAnswer answer={answer} question={questionProperties} />;
    case 'object':
    case 'section':
    case 'sectionZero':
      return <EmptyAnswerText>Not answered</EmptyAnswerText>;
    default:
      return null;
  }
}
