import React from 'react';

import DataLoader from 'vatix-ui/lib/components/DataLoader';

import { useStore } from 'utils/hooks/store';

import { LicenseStatus } from 'utils/api/types';

import VatixMonitoring from 'components/Icons/VatixMonitoring';
import SelfMonitoring from 'components/Icons/SelfMonitoring';

import {
  AvailableLicensesLabel,
  Container,
  LicenseAvailableText,
  LicensesDetailsContainer,
  LicenseStatusText,
  ProductContainer,
  ProductName,
} from './styles';

const LicensesStatus: React.FunctionComponent = (): React.ReactElement => {
  const { licenses } = useStore();

  const renderLicenseStatus = (licenseStatus: LicenseStatus | null): React.ReactNode => (
    <LicenseStatusText>
      <LicenseAvailableText>{licenseStatus?.seatsTaken || 0}</LicenseAvailableText>
      {` / ${licenseStatus?.maxSeats || 0}`}
    </LicenseStatusText>
  );

  return (
    <DataLoader store={licenses}>
      {({ isLoaded, isFailure }) => (
        <Container>
          <AvailableLicensesLabel>
            Available Licences
            {!isLoaded && <br />}
            {isFailure && 'Error'}
            {!isLoaded && !isFailure && 'Loading'}
          </AvailableLicensesLabel>
          {isLoaded && !isFailure && licenses.details && (
            <>
              <ProductContainer>
                <VatixMonitoring />
                <ProductName>Vatix 24/7 Monitoring</ProductName>
                <LicensesDetailsContainer>
                  {renderLicenseStatus(licenses.details.protectorMonitoringVatix)}
                </LicensesDetailsContainer>
              </ProductContainer>

              <ProductContainer>
                <SelfMonitoring />
                <ProductName>Self-Monitoring</ProductName>
                <LicensesDetailsContainer>
                  {renderLicenseStatus(licenses.details.protectorMonitoringSelf)}
                </LicensesDetailsContainer>
              </ProductContainer>
            </>
          )}
        </Container>
      )}
    </DataLoader>
  );
};

export default LicensesStatus;
