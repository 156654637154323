import React from 'react';
import { observer } from 'mobx-react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { useHistory } from 'react-router-dom';

import { Button } from 'vatix-ui/lib/components/Button';

import { useStore } from 'utils/hooks/store';

import { IncidentResponse } from 'utils/api/types';
import { useNavBarHeight } from 'utils/hooks/navbar';
import { UserIncidentOverview } from 'components/BackgroundInformation/styles';

import { UserRole } from 'core/constants';

import NavigationBlocker from 'components/NavigationBlocker';

import CustomModal from 'components/CustomModal';

import TabPanel from 'components/CustomTabPanel/CustomTabPanel';

import routes from 'core/routes';

import IncidentInformation from '../IncidentInformation';
import { IncidentActivitiesContent, IncidentActivitiesFooter } from '../IncidentActivities';

import { StyledTabs, StyledTab, TabsContainer, Wrapper } from './styles';
import OriginalIncident from '../OriginalIncident';
import IncidentDetailsHeader from './components/IncidentDetailsHeader';
import RelatedTasks from '../RelatedTasks';

const IncidentDetails: React.FC = (): React.ReactElement => {
  const {
    incidentDetails,
    incidentDetails: { details: detailsRaw, contentHasBeenEdited },
    session,
    userList,
  } = useStore();

  const history = useHistory();
  const userRole = session.user?.role as UserRole;
  const [value, setValue] = React.useState(0);
  const [blockedTabsChange, setBlockedTabsChange] = React.useState(-1);
  const details = detailsRaw as IncidentResponse;
  const navBarHeight = useNavBarHeight();

  const handelRestore = (): void => {
    setValue(blockedTabsChange);
    setBlockedTabsChange(-1);
    incidentDetails.setContentOfIncidentWasEdited(false);
    incidentDetails.setEditedContent(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    if (contentHasBeenEdited()) {
      setBlockedTabsChange(newValue);
    } else {
      setValue(newValue);
    }
  };

  React.useEffect(() => {
    if (!userList.isLoaded && !userList.isLoading) {
      userList.loadData();
    }
  });

  return (
    <>
      <CustomModal
        modalWidth="sm"
        openModal={blockedTabsChange !== -1}
        onClose={() => setBlockedTabsChange(-1)}
        modalTitle="Unsaved changes"
        buttonsSpacing="space-between"
        cancelButton={
          <Button id="cancel-action-button" onClick={() => setBlockedTabsChange(-1)} variant="outlined" size="large">
            No, keep editing
          </Button>
        }
        submitButton={
          <Button id="add-action-button" onClick={handelRestore} variant="contained" size="large" color="error">
            Yes, discard changes
          </Button>
        }
      >
        <p
          style={{
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '16px',
            letterSpacing: '0.15px',
            margin: '0',
          }}
        >
          You have made updates in the incident record, but you haven’t saved the changes. Do you want to discard these
          changes?
        </p>
      </CustomModal>

      <NavigationBlocker
        when={incidentDetails.contentOfIncidentWasEdited}
        navigate={(path: string) => {
          history.push(path);
          incidentDetails.setContentOfIncidentWasEdited(false);
          incidentDetails.setEditedContent(null);
        }}
        shouldBlockNavigation={() => {
          if (incidentDetails.contentOfIncidentWasEdited) {
            return true;
          }
          return false;
        }}
      />
      {userRole !== UserRole.User ? (
        <Record
          Header={<IncidentDetailsHeader />}
          Content={() => (
            <Wrapper>
              <TabsContainer>
                <StyledTabs value={value} onChange={handleChange}>
                  <StyledTab label="Details" id="incident-overview-tab" />
                  <StyledTab label="Original Submission" id="original-incident-tab" />
                  <StyledTab label="Related" id="related-actions-tab" />
                </StyledTabs>
                <TabPanel value={value} index={0}>
                  <IncidentInformation data={details.details} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <OriginalIncident details={details} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <RelatedTasks />
                </TabPanel>
              </TabsContainer>
            </Wrapper>
          )}
          Activities={{
            Content: <IncidentActivitiesContent />,
            Footer: <IncidentActivitiesFooter />,
          }}
          navBarHeight={navBarHeight}
        />
      ) : (
        <Record
          Header={
            <MainHeader
              title={details.subject}
              breadcrumbs={[
                {
                  label: 'Incidents',
                  link: String(routes.dashboard.incidents),
                },
                {
                  label: 'Overview',
                  link: String(routes.dashboard.incidents),
                },
                {
                  label: 'Details',
                  link: 'Incidents',
                },
              ]}
            />
          }
          Content={() => (
            <UserIncidentOverview>
              <OriginalIncident details={details} />
            </UserIncidentOverview>
          )}
          navBarHeight={navBarHeight}
        />
      )}
    </>
  );
};

export default observer(IncidentDetails);
