import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosResponse, CancelTokenSource } from 'axios';

import { action, runInAction, makeObservable } from 'mobx';

import DetailsActivities from 'vatix-ui/lib/utils/stores/DetailsActivities';

import Logger from 'vatix-ui/lib/utils/logger';

import {
  ActivityListResponse,
  ActivityType,
  ActivityType as VatixActivityType,
  PaginationParams,
  UserSearchResponse,
} from 'vatix-ui/lib/utils/api/types';

import { TaskListResponse } from 'utils/api/types';
import API from 'utils/api';
import RootStore from 'stores/Root';
import { EventType } from 'utils/events/constants';
import { postMessage } from 'utils/events/broadcast';
import { ActionEvent } from 'utils/events/types';

export default class TaskActivities extends DetailsActivities<RootStore, typeof API> {
  taskId: string;

  constructor(rootStore: RootStore, api: typeof API, taskId: string) {
    super(rootStore, api);

    makeObservable(this);

    this.taskId = taskId;

    eventBus.register(this);
  }

  async loadActivitiesData(params: PaginationParams): Promise<AxiosResponse<ActivityListResponse>> {
    return this.api.loadActivitiesTask(this.taskId, params)();
  }

  @action.bound
  async addMessage(message: string, taggedUsers?: UserSearchResponse[]): Promise<void> {
    if (this.error || !this.activities) {
      return;
    }
    try {
      const { data } = await this.api.addTaskMessage(this.taskId, message, taggedUsers)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, { ...data, type: ActivityType.Message as VatixActivityType }];
          postMessage(EventType.AddedMessage, {
            ...data,
            taskId: this.taskId,
          });
        }
      });
    } catch (e) {
      Logger.error(
        `Invalid add message API result: Task ID: ${this.taskId}. Task ID: ${this.taskId}. Message: ${message}`,
        e
      );
    }
  }

  async doUploadFile(
    file: File,
    onUpdateProgress: (event: ProgressEvent) => void,
    cancelTokenSource: CancelTokenSource
  ): Promise<void> {
    try {
      const { data } = await this.api.uploadTaskFile(this.taskId, file, onUpdateProgress, cancelTokenSource)();
      runInAction(() => {
        if (this.activities) {
          this.activities = [...this.activities, data];
          postMessage(EventType.AddedFile, {
            ...data,
            taskId: this.taskId,
          });
        }
      });
    } catch (e) {
      Logger.error(`Invalid add file API result: Task ID: ${this.taskId}.`, e);
    }
  }

  @action.bound
  async deleteFile(fileId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const file = this.activities.find((activity) => activity.uuid === fileId);
    if (file) {
      try {
        await this.api.removeTaskFile(this.taskId, fileId)();
        runInAction(() => {
          this.removeActivity(file.uuid);
          postMessage(EventType.DeletedFile, {
            taskId: this.taskId,
          });
        });
      } catch (e) {
        Logger.error(`Invalid delete file API result: Task ID: ${this.taskId}. File ID: ${fileId}`, e);
      }
    }
  }

  @action.bound
  async deleteMessage(messageId: string): Promise<void> {
    if (this.activities === undefined) {
      return;
    }
    const message = this.activities.find((activity) => activity.uuid === messageId);
    if (message) {
      try {
        await this.api.removeTaskMessage(this.taskId, messageId)();
        runInAction(() => {
          if (this.activities !== undefined) {
            this.activities = this.activities.filter((activity) => activity.uuid !== messageId);
          }
        });
      } catch (e) {
        Logger.error(`Invalid delete message API result: Task ID: ${this.taskId}. Message ID: ${messageId}`, e);
      }
    }
  }

  @subscribe(EventType.CreatedTask)
  @action
  createdTask({ payload }: ActionEvent<TaskListResponse>): void {
    if (this.activities !== undefined && this.taskId === payload.uuid) {
      this.activities = undefined;
      this.loadActivities();
    }
  }

  @subscribe(EventType.UpdatedTask)
  @action
  updatedTask({ payload }: ActionEvent<TaskListResponse>): void {
    if (this.activities !== undefined && this.taskId === payload.uuid) {
      this.activities = undefined;
      this.loadActivities();
    }
  }
}
