/* eslint-disable array-callback-return */
import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { LocationAddressResponse, UserNameResponse } from 'vatix-ui/lib/utils/api/types';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import { EventType } from 'utils/events/constants';

import { TaskStatus } from 'core/constants';

import RootStore from 'stores/Root';
import API from 'utils/api';

import {
  FormsDetailsResponse,
  isMultipleAnswerQuestionType,
  LinkedTaskType,
  LocationProperties,
  TaskListResponse,
  TemplateNameContent,
} from 'utils/api/types';

import { ActionEvent } from 'utils/events/types';

import { MultipleAnswerProperties, QuestionType, SectionType } from 'stores/TemplateDetails/types';

import { ScoreChipColors } from 'containers/FormsDetails/types';

import { isBadRequest } from 'utils/api/errors';

import AnswerDetails from './AnswerDetails';

type Answers = { [key: string]: AnswerDetails };
type ScoreValues = { scoredPoints: string; allPoints: string; average: string; bgColor?: string };

export default class DetailsInspection {
  rootStore: RootStore;

  api: typeof API;

  @observable uuid: string;

  @observable name: string;

  @observable answers: Answers[];

  @observable template: TemplateNameContent;

  @observable location: LocationProperties | null;

  @observable reporter: UserNameResponse;

  @observable submitted: string | null;

  @observable linkedTasks: LinkedTaskType;

  @observable scores: ScoreValues | null;

  @observable questionIds: string[];

  constructor(rootStore: RootStore, api: typeof API, inspectionId: string, data: FormsDetailsResponse) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = api;
    this.uuid = data.uuid;
    this.name = data.name;
    this.template = data.template;
    this.answers = this.getAnswers(inspectionId, data);
    this.reporter = data.reporter;
    this.submitted = data.submitted;
    this.linkedTasks = data.linkedTasks;
    this.location = data.location;
    this.scores = this.getAllScore();
    this.questionIds = this.getQuestionsIds();

    eventBus.register(this);
  }

  @action.bound
  async updateForm(uuid: string, name: string, location: LocationAddressResponse): Promise<boolean> {
    try {
      const { data } = await API.updateForm(uuid, { name, location })();
      this.rootStore.notification.enqueueSuccessSnackbar('Inspection updated successfully');
      this.name = data.name;
      this.location = data.location;
      return true;
    } catch (e) {
      this.rootStore.notification.enqueueErrorSnackbar('Could not update user details');
      // @ts-ignore
      if (!isBadRequest(e)) {
        Logger.error('Invalid update user details API response', e);
      }
      return false;
    }
  }

  getQuestionsIds(): string[] {
    return this.template.content.order.map((sectionId) => this.template.content.properties[sectionId].order).flat(1);
  }

  getAnswers(inspectionId: string, data: FormsDetailsResponse): Answers[] {
    const answers: Answers[] = [];

    this.template.content.order.map((sectionId) => {
      answers.push(
        Object.fromEntries(
          Object.keys(this.template.content.properties[sectionId].properties).map((questionId) => [
            questionId,
            new AnswerDetails(
              this.rootStore,
              this.api,
              inspectionId,
              questionId,
              data.details[questionId],
              data.stats[questionId]
            ),
          ])
        )
      );
    });

    return answers;
  }

  bgColor(average: number): string {
    if (average <= 40) {
      return ScoreChipColors.orange;
    }
    if (average > 40 && average <= 60) {
      return ScoreChipColors.blue;
    }
    if (average > 60 && average <= 99) {
      return ScoreChipColors.yellow;
    }
    if (average === 100) {
      return ScoreChipColors.green;
    }
    return ScoreChipColors.green;
  }

  @action
  getAllScore(): ScoreValues | null {
    if (this.submitted === null) {
      return null;
    }
    const allScoreResult: ScoreValues = { scoredPoints: '', allPoints: '', average: '' };

    this.template.content.order.map((sectionId) => {
      const { allPoints, scoredPoints } = this.getSectionScore(this.template.content.properties[sectionId], sectionId);
      if (!isNaN(Number(scoredPoints)) && !isNaN(Number(allPoints))) {
        allScoreResult.scoredPoints = String(Number(allScoreResult.scoredPoints) + Number(scoredPoints));
        allScoreResult.allPoints = String(Number(allScoreResult.allPoints) + Number(allPoints));
      }
    });

    const average = Math.round((Number(allScoreResult.scoredPoints) / Number(allScoreResult.allPoints)) * 100);

    return {
      scoredPoints: String(allScoreResult.scoredPoints),
      allPoints: String(allScoreResult.allPoints),
      average: `${average}%`,
      bgColor: this.bgColor(average),
    };
  }

  @action
  getSectionScore(section: SectionType, sectionId: string): ScoreValues {
    const sectionScoreResult: ScoreValues = { scoredPoints: '', allPoints: '', average: '', bgColor: '' };

    section.order.forEach((o) => {
      const questionProperties = section.properties[o] as QuestionType;
      const sectionID = this.template.content.order.indexOf(sectionId) as number;

      const questionAnswers = this.answers[sectionID][o];

      const score =
        questionProperties.scoring && isMultipleAnswerQuestionType(questionProperties) && this.submitted
          ? (this.getScore(questionProperties, questionAnswers && (questionAnswers.answer as string[])) as ScoreValues)
          : null;

      if (score) {
        sectionScoreResult.scoredPoints = String(Number(sectionScoreResult.scoredPoints) + Number(score?.scoredPoints));
        sectionScoreResult.allPoints = String(Number(sectionScoreResult.allPoints) + Number(score?.allPoints));
      }
    });

    sectionScoreResult.average = `${Math.round(
      (Number(sectionScoreResult.scoredPoints) / Number(sectionScoreResult.allPoints)) * 100
    )}%`;
    sectionScoreResult.bgColor = this.bgColor(
      Math.round((Number(sectionScoreResult.scoredPoints) / Number(sectionScoreResult.allPoints)) * 100)
    );

    return sectionScoreResult;
  }

  @action
  getScore(properties: MultipleAnswerProperties, answers: string[]): ScoreValues {
    const {
      items: { enum: propEnum },
      scoring,
      maxItems,
    } = properties;
    let sumOfPoints = 0;
    let score = 1;

    answers.forEach((answer) => {
      if (propEnum.includes(answer) && scoring) {
        const index = properties.items.enum.indexOf(answer);
        sumOfPoints += Number(scoring[index]);
      }
    });

    if (maxItems && scoring) {
      score = Math.max(...scoring.map(Number));
    } else {
      score = scoring?.map(Number)?.reduce((sum, current) => sum + current) || 1;
    }

    return {
      scoredPoints: (sumOfPoints as unknown) as string,
      allPoints: (score as unknown) as string,
      average: `${Math.round((sumOfPoints / score) * 100)}%`,
    };
  }

  @subscribe(EventType.CreatedTask)
  @subscribe(EventType.CreatedTaskAnswerTask)
  @action
  async createdTask({ payload }: ActionEvent<TaskListResponse>): Promise<void> {
    if (payload.links.some((link) => link.entity === 'inspection' && link.instance.uuid === this.uuid)) {
      this.linkedTasks = {
        pending: this.linkedTasks.pending + (payload.status !== TaskStatus.Completed ? 1 : 0),
        completed: this.linkedTasks.completed + (payload.status === TaskStatus.Completed ? 1 : 0),
        total: this.linkedTasks.total + 1,
      };
    }
  }

  @subscribe(EventType.UpdatedTask)
  updatedTask({ payload }: ActionEvent<TaskListResponse>): void {
    if (payload.status === TaskStatus.Completed) {
      this.linkedTasks = {
        ...this.linkedTasks,
        pending: this.linkedTasks.pending - 1,
        completed: this.linkedTasks.completed + 1,
      };
    }
  }

  updatedInspection({ payload }: ActionEvent<FormsDetailsResponse>): void {
    if (payload.uuid !== this.uuid) {
      return;
    }
    this.name = payload.name;
    this.template = payload.template;
    this.reporter = payload.reporter;
    this.submitted = payload.submitted;
    this.linkedTasks = payload.linkedTasks;
    this.location = payload.location;
  }
}
