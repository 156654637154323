import React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'vatix-ui/lib/components/Button';
import { useRouteMatch } from 'react-router-dom';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';
import { formatFullDate } from 'utils/formatters/time';
import { useStore } from 'utils/hooks/store';
import CustomModal from 'components/CustomModal';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';
import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';

import API from 'utils/api';

import Logger from 'utils/logger/Logger';

import { EventType } from 'utils/events/constants';
import { postMessage } from 'utils/events/broadcast';

import { DetailsRowContainer, StyledContainer, TitleText } from './styles';

const AddActionModal: React.FunctionComponent<{
  onClose: (created: boolean) => void;
}> = ({ onClose }): React.ReactElement => {
  const { notification } = useStore();

  const [name, setName] = React.useState('');
  const [assigned, setAssigned] = React.useState('');
  const [dueDate, setDueDate] = React.useState('');
  const [disableCreate, setDisableCreate] = React.useState(false);

  const match = useRouteMatch();

  const { formId } = match.params as { formId: string };

  const handleCreate = async (): Promise<void> => {
    setDisableCreate(true);
    const convert = dueDate ? formatFullDate((dueDate as unknown) as string) : '';
    try {
      const { data } = await API.createTaskForForm({
        name,
        assigned,
        dueDate: convert || undefined,
        links: [
          {
            entity: 'inspection',
            instance: formId,
          },
        ],
      })();
      onClose(true);
      setDisableCreate(false);
      postMessage(EventType.CreatedTask, data);
      notification.enqueueSuccessSnackbar('Task created successfully');
    } catch (error) {
      Logger.error('Error creating task', error);
      notification.enqueueErrorSnackbar('Error creating task');
    }
  };

  const date = dueDate ? new Date(dueDate) : null;

  return (
    <CustomModal
      openModal
      onClose={() => onClose(false)}
      modalTitle="Create New Task"
      cancelButton={
        <Button
          id="cancel-button"
          onClick={() => onClose(false)}
          variant="outlined"
          size="large"
          data-testid="cancelButton"
        >
          Cancel
        </Button>
      }
      submitButton={
        <Button
          id="create-action-button"
          onClick={handleCreate}
          disabled={name === '' || disableCreate}
          variant="contained"
          size="large"
          data-testid="addButton"
        >
          Create Task
        </Button>
      }
    >
      <DetailsRowContainer>
        <TitleText>Task Name</TitleText>
        <CustomTextInputField
          value={name}
          onChange={(e) => setName((e as unknown) as string)}
          description="Enter task name"
        />
      </DetailsRowContainer>

      <StyledContainer>
        <DetailsRowContainer>
          <TitleText>Assignee</TitleText>
          <CustomUserDropdownField
            value={assigned}
            onChange={(value) => {
              setAssigned(value ? (value as string).split(':')[1] : '');
            }}
            description="Assigned To"
          />
        </DetailsRowContainer>

        <DetailsRowContainer>
          <TitleText>Due Date</TitleText>
          <CustomDatePicker
            // @ts-ignore
            value={date || undefined}
            onChange={(val) => {
              const convert = val ? formatFullDate(val as string) : '';
              setDueDate(convert);
            }}
          />
        </DetailsRowContainer>
      </StyledContainer>
    </CustomModal>
  );
};

export default observer(AddActionModal);
