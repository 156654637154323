import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';

import CustomIncidentIcon from 'components/Icons/CustomIncident';

import AcceptedRedAlertIcon from 'components/Icons/AcceptedRedAlert';
import AmberAlertIcon from 'components/Icons/AmberAlert';
import BatteryEmptyIcon from 'components/Icons/BatteryEmpty';
import BatteryFullIcon from 'components/Icons/BatteryFull';
import DeviceChargingIcon from 'components/Icons/DeviceCharging';
import CancelledAmberAlertIcon from 'components/Icons/CancelledAmberAlert';
import CancelledRedAlertIcon from 'components/Icons/CancelledRedAlert';
import CompletedRedAlertIcon from 'components/Icons/CompletedRedAlert';
import FinishedAmberAlertIcon from 'components/Icons/FinishedAmberAlert';
import NoteIcon from 'components/Icons/Note';
import RedAlertIcon from 'components/Icons/RedAlert';
import AssignedDeviceIcon from 'components/Icons/AssignedDevice';
import UnassignedDeviceIcon from 'components/Icons/UnassignedDevice';
import AssignedGroupIcon from 'components/Icons/AssignedGroup';
import UnassignedGroupIcon from 'components/Icons/UnassignedGroup';
import { Indexable } from 'utils/types';

export enum ActivityType {
  RedAlert = 'red_alert',
  AmberAlert = 'amber_alert',
  CreatedIncident = 'created_incident',
  Battery = 'battery',
  DeviceCharging = 'device_charging',
  Message = 'message',
  DeviceAssignment = 'device_assignment',
  GroupAssignment = 'group_assignment',
  UserAdded = 'user',
  VoiceMemo = 'voice_memo',
  Incident = 'incident',
  FieldChange = 'field_change',
  IncidentSubjectChange = 'incident_subject_change',
  IncidentLocationChange = 'incident_location_change',
  Assignment = 'assignment',
  Owner = 'owner',
  State = 'state',
  Status = 'status',
}

export const ActivityTypeName = Object.freeze({
  [ActivityType.RedAlert]: 'Alert',
  [ActivityType.AmberAlert]: 'Timed Alert',
  [ActivityType.Battery]: 'Battery',
  [ActivityType.DeviceCharging]: 'Device Charging',
  [ActivityType.Message]: 'Message',
  [ActivityType.DeviceAssignment]: 'Device Assignment',
  [ActivityType.GroupAssignment]: 'Team Assignment',
  [ActivityType.VoiceMemo]: 'Voice Memo',
  [ActivityType.Incident]: 'Incident',
  [ActivityType.CreatedIncident]: 'Created Incident',
  [ActivityType.UserAdded]: 'User Added',
  [ActivityType.FieldChange]: 'Field Change',
  [ActivityType.IncidentSubjectChange]: 'Subject Change',
  [ActivityType.IncidentLocationChange]: 'Location Change',
  [ActivityType.Assignment]: 'Assignment Change',
  [ActivityType.Owner]: 'Owner Change',
  [ActivityType.State]: 'State Change',
  [ActivityType.Status]: 'Status Change',
});

export enum BatteryActivityStatus {
  Low = 'low',
  Ok = 'ok',
}

export enum DeviceChargingActivityStatus {
  Charging = 'charging',
  NotCharging = 'not_charging',
}

export enum RedAlertActivityStatus {
  Started = 'started',
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  Completed = 'completed',
}

export enum AmberAlertActivityStatus {
  Started = 'started',
  Cancelled = 'cancelled',
  Finished = 'finished',
}

export enum AssignmentStatus {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
}

export enum IncidentActivityStatus {
  Archived = 'archived',
  Open = 'open',
  Resolved = 'resolved',
}

export const ActivityIcon: Indexable<React.ComponentType | Indexable<React.ComponentType>> = Object.freeze({
  [ActivityType.RedAlert]: {
    [RedAlertActivityStatus.Accepted]: AcceptedRedAlertIcon,
    [RedAlertActivityStatus.Cancelled]: CancelledRedAlertIcon,
    [RedAlertActivityStatus.Completed]: CompletedRedAlertIcon,
    [RedAlertActivityStatus.Started]: RedAlertIcon,
  },
  [ActivityType.AmberAlert]: {
    [AmberAlertActivityStatus.Started]: AmberAlertIcon,
    [AmberAlertActivityStatus.Cancelled]: CancelledAmberAlertIcon,
    [AmberAlertActivityStatus.Finished]: FinishedAmberAlertIcon,
  },
  [ActivityType.Battery]: {
    [BatteryActivityStatus.Low]: BatteryEmptyIcon,
    [BatteryActivityStatus.Ok]: BatteryFullIcon,
  },
  [ActivityType.DeviceCharging]: {
    [DeviceChargingActivityStatus.Charging]: DeviceChargingIcon,
    [DeviceChargingActivityStatus.NotCharging]: DeviceChargingIcon,
  },
  [ActivityType.Message]: NoteIcon,
  [ActivityType.DeviceAssignment]: {
    [AssignmentStatus.Assigned]: AssignedDeviceIcon,
    [AssignmentStatus.Unassigned]: UnassignedDeviceIcon,
  },
  [ActivityType.GroupAssignment]: {
    [AssignmentStatus.Assigned]: AssignedGroupIcon,
    [AssignmentStatus.Unassigned]: UnassignedGroupIcon,
  },
  [ActivityType.VoiceMemo]: PhoneIcon,
  [ActivityType.Incident]: {
    [IncidentActivityStatus.Archived]: CustomIncidentIcon,
    [IncidentActivityStatus.Open]: CustomIncidentIcon,
    [IncidentActivityStatus.Resolved]: CustomIncidentIcon,
  },
});

export const ActivityStatusName: Indexable<Indexable<string>> = Object.freeze({
  [ActivityType.RedAlert]: {
    [RedAlertActivityStatus.Accepted]: 'Accepted',
    [RedAlertActivityStatus.Cancelled]: 'Cancelled',
    [RedAlertActivityStatus.Completed]: 'Completed',
    [RedAlertActivityStatus.Started]: 'Started',
  },
  [ActivityType.AmberAlert]: {
    [AmberAlertActivityStatus.Started]: 'Started',
    [AmberAlertActivityStatus.Cancelled]: 'Cancelled',
    [AmberAlertActivityStatus.Finished]: 'Finished',
  },
  [ActivityType.Battery]: {
    [BatteryActivityStatus.Low]: 'Low',
    [BatteryActivityStatus.Ok]: 'Ok',
  },
  [ActivityType.DeviceCharging]: {
    [DeviceChargingActivityStatus.Charging]: 'On',
    [DeviceChargingActivityStatus.NotCharging]: 'Off',
  },
  [ActivityType.DeviceAssignment]: {
    [AssignmentStatus.Assigned]: 'Assigned',
    [AssignmentStatus.Unassigned]: 'Unassigned',
  },
  [ActivityType.GroupAssignment]: {
    [AssignmentStatus.Assigned]: 'Assigned',
    [AssignmentStatus.Unassigned]: 'Unassigned',
  },
  [ActivityType.Incident]: {
    [IncidentActivityStatus.Archived]: 'Archived',
    [IncidentActivityStatus.Open]: 'Opened',
    [IncidentActivityStatus.Resolved]: 'Resolved',
  },
});
