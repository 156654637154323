import React from 'react';
import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { formatFullDateTime } from 'utils/formatters/time';

import { TemplateListResponse } from 'utils/api/types';

import routes from 'core/routes';

import ActiveColumn from '../ActiveColumn';
import ActionCell from '../ActionCell';
import ActiveFilterOperator from '../ActiveFilterOperator';

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    nativeFilter: 'query',
  },
  {
    field: 'creator',
    headerName: 'Creator',
    nativeFilter: 'creator',
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    filterOperators: defaultOperators.user(),
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
  },
  {
    field: 'lastUpdate',
    nativeFilter: 'last_update',
    headerName: 'Last modified',
    filterOperators: defaultOperators.date_time(),
    valueFormatter: defaultFormatters.date,
    renderCell: ({ value }: { value: string }) => formatFullDateTime(value),
  },
  {
    field: 'isActive',
    headerName: 'Status',
    renderCell: ({ row, colDef }: { row: TemplateListResponse; colDef: ColumnDefinition }) => (
      <ActiveColumn row={row} actions={colDef.actions} />
    ),
    filterOperators: ActiveFilterOperator(),
    nativeFilter: 'is_active',
    sortingField: 'is_active',
  },
  {
    maxWidth: 50,
    headerName: '',
    type: 'actions',
    field: 'actions',
    ariaLabel: 'actions',
    renderCell: ({ row }: { row: TemplateListResponse }) => <ActionCell rowData={row} />,
  },
] as unknown) as ColumnDefinition[];

const FormTemplatesList: React.FunctionComponent<{ onError: (error: VatixError) => void }> = ({
  onError,
}): React.ReactElement => {
  const history = useHistory();
  const onRowClick: GridEventListener<'rowClick'> = (template: GridRowParams): void => {
    history.push(reverse(routes.dashboard.audits.templates.details, { templateId: template.row.uuid }));
  };

  return (
    <Grid
      onRowClick={onRowClick}
      basicColumns={basicColumns}
      entity="forms templates"
      onError={onError}
      dataURL={`${process.env.REACT_APP_WORKFLOWS_API_URL}templates/`}
      sortBy={{ field: 'lastUpdate', sort: 'desc' }}
      sortingMapping={{
        lastUpdate: 'last_update',
      }}
      disableColumnSelector
    />
  );
};

export default FormTemplatesList;
