import React from 'react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import { UuidableName, SearchResponse } from 'vatix-ui/lib/utils/api/types';

import { Box } from '@mui/material';

import { TaskListResponse, UuidableSubject } from 'utils/api/types';

import API from 'utils/api';

import { StyledAutocomplete, StyledTextField } from 'components/Input/styles';

import { StyledMenuItem, EditTaskRowName } from '../../EditTaskProperties/styles';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as SearchResponse;
const nullOption = { uuid: 'null', name: '' } as SearchResponse;

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditTaskRowName>{name}</EditTaskRowName>
    <div>{children}</div>
  </div>
);

const InspectionField: React.FunctionComponent<{
  updateField: (field: string, value: string | UuidableSubject) => void;
  taskDetailsData: TaskListResponse;
}> = ({ updateField, taskDetailsData }): React.ReactElement => {
  const {
    results: inspectionsList,
    loading: loadingInspections,
    searchQuery: searchQueryInspections,
  } = useSearch(API.searchInspections, undefined, { limit: 10 });

  const [selectedInspection, setSelectedInspection] = React.useState<SearchResponse | null | undefined>(undefined);

  const onChangeSearch = async (s: string): Promise<void> => {
    await searchQueryInspections(s);
  };

  React.useEffect(() => {
    setSelectedInspection({
      name: taskDetailsData.links[0] ? taskDetailsData.links[0].instance.name : '',
      uuid: taskDetailsData.links[0] ? taskDetailsData.links[0].instance.uuid : '',
    });
  }, []);

  return (
    <Row name="Form" id="field-2">
      <StyledAutocomplete
        key={`${selectedInspection?.name}_${selectedInspection?.uuid}`}
        id="inspection-dropdown-field"
        options={inspectionsList}
        value={selectedInspection === undefined ? nullOption : selectedInspection}
        renderOption={(props, option: UuidableName) => {
          const { ...restProps } = props;
          return option.uuid === loadingOption.uuid ? (
            <CenteredCircularProgress />
          ) : (
            <Box sx={{ alignItems: 'center' }}>
              <StyledMenuItem
                key={`${option.uuid}`}
                {...restProps}
                value={option.uuid}
                onClick={() => {
                  setSelectedInspection({
                    name: option.name,
                    uuid: option.uuid,
                  });

                  updateField(
                    'links',
                    // @ts-ignore
                    [{ ...taskDetailsData.links, instance: { name: option.name, uuid: option.uuid } }]
                  );
                }}
              >
                {' '}
                {option.name}{' '}
              </StyledMenuItem>
            </Box>
          );
        }}
        getOptionLabel={(option: SearchResponse) => option?.name as string}
        getOptionDisabled={(option: SearchResponse) =>
          option.uuid === loadingOption.uuid || option.uuid === selectedInspection?.uuid
        }
        renderInput={(params) => (
          <StyledTextField
            {...params}
            onChange={(e) => {
              onChangeSearch(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              type: 'search',
            }}
            placeholder="Search"
          />
        )}
        loading={loadingInspections}
        fullWidth
        disableClearable={!selectedInspection?.uuid}
        onChange={() => {
          setSelectedInspection(undefined);
          searchQueryInspections('');
        }}
        onInputChange={(_, __, reason) => {
          if (reason === 'clear') {
            updateField(
              'links',
              // @ts-ignore
              []
            );
          }
        }}
      />
    </Row>
  );
};

export default InspectionField;
