import React from 'react';
import { observer } from 'mobx-react';
import { reverse } from 'named-urls';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import routes from 'core/routes';
import { useStore } from 'utils/hooks/store';

import Grid from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';
import { ESCALATION_TYPE_LABELS, MonitoringMethod } from 'core/constants';

import { EscalationProfileResponse, JSONSchemaValue } from 'utils/api/types';

import Chip from 'components/Chip';

import VatixMonitoring from 'components/Icons/VatixMonitoring';

import SelfMonitoring from 'components/Icons/SelfMonitoring';

import ActionCell from '../ActionCell';
import MonitoringTypeOperator from '../MonitoringTypeOperator';

const basicColumns = ([
  {
    field: 'name',
    headerName: 'Name',
    valueGetter: (params: GridValueGetterParams) => ({
      name: params.row.name,
      escalationType: params.row.escalationType,
    }),
    renderCell: ({ value }: { value: { name: string; escalationType: string } }) => (
      <Chip
        label={value.name}
        colors={{ background: 'transparent', foreground: 'rgba(0, 0, 0, 0.87)' }}
        size="medium"
        variant="outlined"
        iconLeft={value.escalationType === MonitoringMethod.ArcMonitored ? <VatixMonitoring /> : <SelfMonitoring />}
        iconLeftGapWidth={6}
      />
    ),
  },
  {
    field: 'escalationType',
    headerName: 'Monitoring type',
    renderCell: ({ value }: { value: JSONSchemaValue<EscalationProfileResponse> }) => (
      <>{ESCALATION_TYPE_LABELS[(value as unknown) as MonitoringMethod]}</>
    ),
    filterOperators: MonitoringTypeOperator,
    nativeFilter: 'escalation_type',
    sortingField: 'escalation_type',
  },
  {
    field: 'numberOfUsers',
    headerName: 'Number of users',
    filterable: false,
    // ToDo: implement sorting on backend
    sortable: false,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: EscalationProfileResponse; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
  },
] as unknown) as ColumnDefinition[];

const EscalationProfileList: React.FunctionComponent<{
  onError: (error: VatixError) => void;
}> = ({ onError }) => {
  const { routing } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.alarms.escalationProfiles.details, { escalationProfileId: item.id }));
  };

  return (
    <Grid
      basicColumns={basicColumns}
      onError={onError}
      onRowClick={onRowClick}
      dataURL="/api/alarms/escalation-profiles/"
      disableSelectAllColumns
      disableColumnSelector
    />
  );
};

export default observer(EscalationProfileList);
