import styled from 'styled-components';
import { RichTextInput } from 'vatix-ui/lib/components/TextInput';

export const StyledTextInput = styled(RichTextInput)``;

export const TaskName = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
`;

export const ItemContainer = styled.div`
  flex: 1;
  max-width: 33%;
  .MuiButtonBase-root {
    font-size: 14px;
  }
`;

export const SpinnerContainer = styled.div`
  margin: 8px auto;
  min-height: 30px;
  display: flex;
  justify-content: center;
`;
