import React from 'react';

import { StyledTextInput } from './styles';
import { QuestionRowProps } from './types';
import { EmptyAnswerText } from '../styles';

const TextAnswer: React.FunctionComponent<QuestionRowProps> = ({ answer }): React.ReactElement => (
  <>
    {answer?.answer ? (
      <StyledTextInput id="question-answer">{answer?.answer as string}</StyledTextInput>
    ) : (
      <EmptyAnswerText>Not answered</EmptyAnswerText>
    )}
  </>
);

export default TextAnswer;
