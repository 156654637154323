import React from 'react';
import { observer } from 'mobx-react';

import FeedComponent from 'vatix-ui/lib/components/Feed';
import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';
import { FeedProps } from 'vatix-ui/lib/components/Feed/types';

import { USER_TAG_BASE } from 'core/constants';
import RootStore from 'stores/Root';
import { SessionStoreWithUser } from 'stores/Session/types';
import IncidentActivitiesStore from 'stores/IncidentDetails/IncidentActivities';
import API from 'utils/api';
import { ActivityType } from 'utils/stores/Activities/constants';
import { useStore } from 'utils/hooks/store';

import IncidentActivity from './components/IncidentActivity/IncidentActivity';
import IncidentFieldChange from './components/IncidentFieldChange';
import IncidentAssignment from './components/IncidentAssignment';
import IncidentSubjectChange from './components/IncidentSubjectChange';
import IncidentLocationChange from './components/IncidentLocationChange/IncidentLocationChange';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.Incident]: IncidentActivity,
  [ActivityType.FieldChange]: IncidentFieldChange,
  [ActivityType.IncidentSubjectChange]: IncidentSubjectChange,
  [ActivityType.IncidentLocationChange]: IncidentLocationChange,
  [ActivityType.Assignment]: IncidentAssignment,
} as unknown) as FeedProps<RootStore, typeof API>['mapping'];

const IncidentActivitiesContent: React.FunctionComponent = (): React.ReactElement => {
  const { session: sessionRaw, incidentDetails } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const activities = incidentDetails.activities as IncidentActivitiesStore;

  return (
    <FeedComponent
      originalActivities={activities}
      mapping={activityTypeToComponent}
      usersSearch={API.searchUsers}
      onDeleteComment={activities.deleteMessage}
      onDeleteFile={activities.deleteFile}
      sessionUser={session.user}
      downloadFile={API.downloadFile}
      userTagBaseUrl={USER_TAG_BASE}
    />
  );
};

export default observer(IncidentActivitiesContent);
