import React from 'react';

import { useHistory, useRouteMatch } from 'react-router-dom';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import { reverse } from 'named-urls';

import { observer } from 'mobx-react';

import { IconButton, MenuItem } from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import Logger from 'vatix-ui/lib/utils/logger/Logger';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import LinkedTasksCell from 'containers/Incidents/components/LinkedTasksCell';
import { LinkedTaskType } from 'utils/api/types';

import API from 'utils/api';

import UserField from 'containers/IncidentDetails/components/UserField';

import { ScoreChip } from 'containers/FormsDetails/styles';

import { ScoreChipColors } from 'containers/FormsDetails/types';

import { ButtonWrapper, MainInformation, MainInformationColumn, MainInformationTitle, StyledPopover } from './styles';
import RemoveFormModal from '../RemoveFormModal';

const FormDetailsHeader: React.FunctionComponent = (): React.ReactElement => {
  const { formDetails, notification } = useStore();
  const history = useHistory();
  const match = useRouteMatch();
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleRemoveClick = async (): Promise<void> => {
    setOpenModal(true);
  };
  const closeModal = (): void => {
    setOpenModal(false);
  };

  const { formId } = match.params as { formId: string };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const onDelete = async (): Promise<void> => {
    try {
      await API.deleteForm(formDetails.details?.uuid as string)();
      notification.enqueueSuccessSnackbar('Form deleted successfully');
      history.push(reverse(routes.dashboard.audits.toString()));
    } catch (ex) {
      Logger.error('Invalid delete form API response.', ex);
      notification.enqueueErrorSnackbar('Form could not be deleted');
    }
  };

  const onDownloadPDF = (): void => {
    API.downloadFormPDFReport(formDetails.details?.uuid as string)();
  };

  return (
    <>
      <MainHeader
        title={formDetails.details?.name || 'N/A'}
        breadcrumbs={[
          { label: 'Audits', link: routes.dashboard.audits.toString() },
          { label: 'Forms', link: routes.dashboard.audits.forms.toString() },
          {
            label: 'Form Details',
            link: reverse(routes.dashboard.audits.forms.details, { formId }),
          },
        ]}
        action={
          <>
            <ButtonWrapper>
              <IconButton onClick={handleClick}>
                <MoreVertIcon htmlColor="rgba(0, 0, 0, 0.54)" />
              </IconButton>
            </ButtonWrapper>
            <StyledPopover
              id="popover-form-details"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem id="export-button" onClick={onDownloadPDF}>
                Export
              </MenuItem>
              <MenuItem id="remove-button" onClick={handleRemoveClick}>
                Delete
              </MenuItem>
            </StyledPopover>
          </>
        }
      />

      <MainInformation>
        {formDetails.details && formDetails.details.scores && formDetails.details.scores?.allPoints !== '0' ? (
          <MainInformationColumn id="form-details-overall-score-column">
            <MainInformationTitle>Overall Score</MainInformationTitle>
            <ScoreChip $bgColor={formDetails.details.scores.bgColor || ScoreChipColors.blue}>
              {formDetails.details.scores.average}
            </ScoreChip>
          </MainInformationColumn>
        ) : null}
        <MainInformationColumn id="form-details-reporter-column">
          <MainInformationTitle>Reporter</MainInformationTitle>
          {formDetails.details?.reporter ? (
            <UserField userId={formDetails.details?.reporter?.uuid as string} />
          ) : (
            <p>N/A</p>
          )}
        </MainInformationColumn>
        <MainInformationColumn id="form-details-linked-actions-column">
          <MainInformationTitle>Linked Actions</MainInformationTitle>
          <LinkedTasksCell linkedTasks={formDetails.details?.linkedTasks as LinkedTaskType} />
        </MainInformationColumn>
        <MainInformationColumn id="form-details-submitted-column">
          <MainInformationTitle>Submitted</MainInformationTitle>
          {formDetails.details?.submitted ? (
            <p id={`form-submitted-${formDetails.details?.submitted}`}>
              {formatDateForDisplay(formDetails.details?.submitted)}
            </p>
          ) : (
            <p id="form-submitted-empty">{formDetails.details?.submitted === null ? 'Not submitted' : 'N/A'}</p>
          )}
        </MainInformationColumn>
      </MainInformation>
      {openModal && <RemoveFormModal onClose={closeModal} handleRemoveForm={onDelete} />}
    </>
  );
};

export default observer(FormDetailsHeader);
