import React from 'react';

import { GridFilterItem } from '@mui/x-data-grid/models/gridFilterItem';
import { escapeRegExp } from 'lodash';
import { GridFilterOperator } from '@mui/x-data-grid';

import { JSONSchemaType } from 'utils/api/types';
import { PickerProps } from 'components/Grid/operators/types';

import IncidentStatusSelect from 'components/GridFilterStatusSelect/IncidentStatusSelect';
import {
  AmberAlertStatus,
  AmberAlertStatusName,
  IncidentsStatus,
  RedAlertStatus,
  TaskStatus,
  TaskStatusNames,
} from 'core/constants';
import AlarmStatusSelect from 'components/GridFilterStatusSelect/AlarmStatusSelect';
import TaskStatusSelect from 'components/GridFilterStatusSelect/TaskStatusSelect';
import AmberAlarmStatusSelect from 'components/GridFilterStatusSelect/AmberAlarmStatusSelect';

const BaseStatusOperator = (
  PickerComponent: React.FunctionComponent<PickerProps>,
  props: { schema?: JSONSchemaType } | undefined,
  customGetValueAsString?: (value: string) => string
): GridFilterOperator[] =>
  ([
    {
      label: 'is',
      value: 'is',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: PickerComponent,
      InputComponentProps: {
        schema: props?.schema as JSONSchemaType,
        value: null,
      },
      getValueAsString: (value: string) => (customGetValueAsString ? customGetValueAsString(value) : value),
    },
    {
      label: 'is not',
      value: 'is not',
      getApplyFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem.value) {
          return null;
        }
        const filterRegex = new RegExp(escapeRegExp(filterItem.value.trim()), 'i');
        return ({ value }: { value: string }): boolean => (value != null ? filterRegex.test(value.toString()) : false);
      },
      InputComponent: PickerComponent,
      InputComponentProps: {
        schema: props?.schema as JSONSchemaType,
        value: null,
      },
      getValueAsString: (value: string) =>
        customGetValueAsString ? customGetValueAsString(value.slice(1)) : value.slice(1),
    },
  ] as unknown) as GridFilterOperator[];

const IncidentStatusPicker: React.FunctionComponent<PickerProps> = ({ applyValue, item, schema, ...props }) => (
  <IncidentStatusSelect
    {...props}
    schema={schema as JSONSchemaType}
    onChange={(newValue) => applyValue({ ...item, value: newValue })}
    value={
      item.value && (item.value as string).startsWith('-')
        ? ((item.value as string).slice(1) as IncidentsStatus)
        : (item.value as IncidentsStatus)
    }
  />
);

export const AlarmStatusPicker: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <AlarmStatusSelect
    {...props}
    onChange={(newValue) => applyValue({ ...item, value: newValue })}
    value={
      item.value && (item.value as string).startsWith('-')
        ? ((item.value as string).slice(1) as RedAlertStatus)
        : (item.value as RedAlertStatus)
    }
  />
);

export const TaskStatusPicker: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <TaskStatusSelect
    {...props}
    onChange={(newValue) => applyValue({ ...item, value: newValue })}
    value={
      item.value && (item.value as string).startsWith('-')
        ? ((item.value as string).slice(1) as TaskStatus)
        : (item.value as TaskStatus)
    }
  />
);

export const AmberAlarmStatusPicker: React.FunctionComponent<PickerProps> = ({ applyValue, item, ...props }) => (
  <AmberAlarmStatusSelect
    {...props}
    onChange={(newValue) => applyValue({ ...item, value: newValue })}
    value={
      item.value && (item.value as string).startsWith('-')
        ? ((item.value as string).slice(1) as AmberAlertStatus)
        : (item.value as AmberAlertStatus)
    }
  />
);

export const IncidentStatusOperator = (props?: { schema?: JSONSchemaType }): GridFilterOperator[] =>
  BaseStatusOperator(IncidentStatusPicker, props, (value) => value[0].toUpperCase() + value.slice(1));

export const AlarmStatusOperator = (props?: { schema?: JSONSchemaType }): GridFilterOperator[] =>
  BaseStatusOperator(AlarmStatusPicker, props, (value) => value[0].toUpperCase() + value.slice(1));

export const TaskStatusOperator = (props?: { schema?: JSONSchemaType }): GridFilterOperator[] =>
  BaseStatusOperator(TaskStatusPicker, props, (value) => TaskStatusNames[value as TaskStatus]);

export const AmberAlarmStatusOperator = (props?: { schema?: JSONSchemaType }): GridFilterOperator[] =>
  BaseStatusOperator(AmberAlarmStatusPicker, props, (value) => AmberAlertStatusName[value as AmberAlertStatus]);
