import { observer } from 'mobx-react';
import React from 'react';

import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import { useStore } from 'utils/hooks/store';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import { isMultipleAnswerQuestionType } from 'utils/api/types';

import { QuestionType } from 'stores/TemplateDetails/types';

import ListIcon from 'components/Icons/ListIcon';

import { getAnswerComponent } from 'containers/FormsDetails/utils';

import {
  QuestionHeader,
  QuestionTitle,
  MandatoryQuestion,
  AnswerRow,
  StatsContainer,
  SummaryCounter,
  ScoreCounter,
} from './styles';

import { EmptyAnswerText } from '../styles';

import { QuestionRowProps } from './types';

type ScoreValues = { scoredPoints: string; allPoints: string; average: string; bgColor?: string };

const QuestionRow: React.FunctionComponent<QuestionRowProps> = ({
  questionId,
  showStats,
  sectionId,
}): React.ReactElement => {
  const {
    formDetails: { details },
  } = useStore();

  const sectionID = details?.template.content.order.indexOf(sectionId) as number;
  const position = details?.questionIds.indexOf(questionId) as number;
  const questionProperties = details?.template.content.properties[sectionId].properties[questionId] as QuestionType;
  const answer = details?.answers[sectionID][questionId] as AnswerDetails;
  const isMandatory = details?.template.content.properties[sectionId].mandatory.includes(questionId);
  const score =
    isMultipleAnswerQuestionType(questionProperties) && details?.submitted
      ? (details?.getScore(questionProperties, answer.answer as string[]) as ScoreValues)
      : null;

  return (
    <>
      <QuestionHeader $sectionZero={sectionId === '0'}>
        <QuestionTitle>
          {position + 1} - {questionProperties?.description}
          {isMandatory ? <MandatoryQuestion>*</MandatoryQuestion> : null}
        </QuestionTitle>
      </QuestionHeader>
      <AnswerRow>
        {questionProperties ? (
          getAnswerComponent(questionProperties.workflowsType || questionProperties.type, answer, questionProperties)
        ) : (
          <EmptyAnswerText>Not answered</EmptyAnswerText>
        )}

        {showStats && (
          <StatsContainer>
            {answer.stats?.comments ? (
              <>
                <ChatBubbleOutlineIcon htmlColor="rgba(0, 0, 0, 0.6)" style={{ width: '20px', height: '20px' }} />
                <SummaryCounter id="comments-counter-summary">{answer.stats.comments}</SummaryCounter>
              </>
            ) : null}
            {answer.stats?.files ? (
              <>
                <UploadFileIcon htmlColor="rgba(0, 0, 0, 0.6)" style={{ width: '20px', height: '20px' }} />
                <SummaryCounter id="files-counter-summary">{answer.stats.files}</SummaryCounter>
              </>
            ) : null}
            {answer.stats?.tasks ? (
              <>
                <ListIcon fontSize="small" />
                <SummaryCounter id="tasks-counter-summary">{answer.stats.tasks}</SummaryCounter>
              </>
            ) : null}
            {score && questionProperties.scoring && score.allPoints ? (
              <ScoreCounter id="task-score-summary">
                <p>{`${score.scoredPoints}/${score.allPoints} | ${score.average}`}</p>
              </ScoreCounter>
            ) : null}
          </StatsContainer>
        )}
      </AnswerRow>
    </>
  );
};

export default observer(QuestionRow);
