import React from 'react';

import { observer } from 'mobx-react';

import DataError from 'vatix-ui/lib/components/Error/DataError';
import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';

import AddIcon from '@mui/icons-material/Add';

import { TaskListProps } from './types';
import { TaskContainer, TasksHeader, NewTaskLink } from './styles';
import TaskListItem from '../TaskListItem';

const TaskList: React.FunctionComponent<TaskListProps> = ({ tasks, onSelected }): React.ReactElement => {
  React.useEffect(() => {
    if (tasks.tasks === undefined) {
      tasks.loadTasks();
    }
  }, [tasks]);

  const renderTasks = (): React.ReactElement | React.ReactElement[] => {
    if (tasks.error) {
      return (
        <TaskContainer>
          <DataError onReload={tasks.loadTasks} message="We are unable to load tasks" />
        </TaskContainer>
      );
    }

    if (tasks.tasks === undefined) {
      return (
        <TaskContainer>
          <CenteredCircularProgress />
        </TaskContainer>
      );
    }

    return (
      <>
        {tasks.tasks.map((task) => (
          <TaskContainer onClick={() => task.uuid && onSelected(task)} key={task.uuid}>
            <TaskListItem task={task} store={tasks} />
          </TaskContainer>
        ))}
        {!tasks.editingTask ? (
          <NewTaskLink onClick={() => tasks.createGhostTask()} id="new-task-link">
            <AddIcon />
            New Task
          </NewTaskLink>
        ) : null}
      </>
    );
  };

  return (
    <>
      <TasksHeader>Tasks</TasksHeader>
      {renderTasks()}
    </>
  );
};

export default observer(TaskList);
