import { observer } from 'mobx-react';
import React from 'react';

import { QuestionAnswer } from './styles';

import { QuestionRowProps } from './types';
import { EmptyAnswerText } from '../styles';

const BooleanAnswer: React.FunctionComponent<QuestionRowProps> = ({ answer }): React.ReactElement => {
  const ANSWERS = {
    [String(true)]: <QuestionAnswer id="question-answer-yes">Yes</QuestionAnswer>,
    [String(false)]: <QuestionAnswer id="question-answer-no">No</QuestionAnswer>,
    [String(null)]: <QuestionAnswer id="question-answer-na">N/A</QuestionAnswer>,
    [String(undefined)]: <EmptyAnswerText id="question-answer-undefined">Not answered</EmptyAnswerText>,
  };

  return <>{ANSWERS[String(answer?.answer)]}</>;
};

export default observer(BooleanAnswer);
