import { action, observable, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { ActivityResponse, MessageActivityResponse } from 'vatix-ui/lib/utils/api/types';

import RootStore from 'stores/Root';
import { LocationProperties } from 'stores/TemplateDetails/types';
import API from 'utils/api';
import { AnswerStats, InspectionTaskLinkType, TaskListResponse } from 'utils/api/types';
import { EventType } from 'utils/events/constants';
import { ActionEvent } from 'utils/events/types';

import AnswerActivities from './AnswerActivities';
import AnswerTasks from './AnswerTasks';

interface MessageActivityPayload extends MessageActivityResponse {
  inspectionId: string;
  questionId: string;
}
interface TaskActivityResponse extends ActivityResponse {
  inspectionId?: string;
  questionId?: string;
}

export default class AnswerDetails {
  rootStore: RootStore;

  api: typeof API;

  inspectionId: string;

  questionId: string;

  @observable activities: AnswerActivities;

  @observable tasks: AnswerTasks;

  @observable answer: string | string[] | LocationProperties | boolean;

  @observable stats?: AnswerStats;

  constructor(
    rootStore: RootStore,
    api: typeof API,
    inspectionId: string,
    questionId: string,
    data: string | string[] | LocationProperties | boolean,
    stats: AnswerStats
  ) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.api = api;
    this.inspectionId = inspectionId;
    this.questionId = questionId;
    this.answer = data;
    this.activities = new AnswerActivities(rootStore, api, inspectionId, questionId);
    this.tasks = new AnswerTasks(rootStore, api, inspectionId, questionId);
    this.stats = stats;

    eventBus.register(this);
  }

  @subscribe(EventType.AddedMessage)
  @action
  addedMessage({ payload }: ActionEvent<MessageActivityPayload>): void {
    if (
      this.stats !== undefined &&
      payload.inspectionId === this.inspectionId &&
      payload.questionId === this.questionId
    ) {
      this.stats.comments += 1;
    }
  }

  @subscribe(EventType.DeletedMessage)
  @action
  deletedMessage({ payload }: ActionEvent<MessageActivityPayload>): void {
    if (
      this.stats !== undefined &&
      payload.inspectionId === this.inspectionId &&
      payload.questionId === this.questionId
    ) {
      this.stats.comments -= 1;
    }
  }

  @subscribe(EventType.CreatedTask)
  @subscribe(EventType.CreatedTaskAnswerTask)
  @action
  createdTask({ payload }: ActionEvent<TaskListResponse>): void {
    if (
      this.stats !== undefined &&
      payload.links.some(
        (link) =>
          link.instance.uuid === this.inspectionId &&
          (link.extraData as InspectionTaskLinkType).questionId === this.questionId
      )
    ) {
      this.stats.tasks += 1;
    }
  }

  @subscribe(EventType.AddedFile)
  @action
  createdFile({ payload }: ActionEvent<TaskActivityResponse>): void {
    if (
      this.stats !== undefined &&
      payload.inspectionId === this.inspectionId &&
      payload.questionId === this.questionId
    ) {
      this.stats.files += 1;
    }
  }

  @subscribe(EventType.DeletedFile)
  @action
  deletedFile({ payload }: ActionEvent<TaskActivityResponse>): void {
    if (
      this.stats !== undefined &&
      payload.inspectionId === this.inspectionId &&
      payload.questionId === this.questionId
    ) {
      this.stats.files -= 1;
    }
  }
}
