import React from 'react';

import { reverse } from 'named-urls';

import { GridEventListener, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import Grid, { defaultFormatters, defaultOperators, defaultRenderers } from 'components/Grid';
import AmberAlarmStatusChip from 'components/Chips/AmberAlarmStatusChip';
import { StyledCreated } from 'containers/Incidents/components/IncidentList/styles';
import { formatDateForDisplay } from 'utils/formatters/time';
import { AmberAlertStatus } from 'core/constants';

import { ColumnDefinition } from 'components/Grid/types';
import { AmberAlarmStatusOperator } from 'components/Grid/operators/Status/Status';

import { EventType } from 'utils/events/constants';

import TimeCell from '../TimeCell';
import { AmberAlarmListProps } from './types';

const basicColumns = ([
  {
    field: 'user',
    headerName: 'Started by',
    filterOperators: defaultOperators.user(),
    renderCell: defaultRenderers.user,
    valueFormatter: defaultFormatters.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    // TODO It does not make sense, it is added by the Grid.
    nativeFilter: true,
  },
  {
    field: 'location',
    headerName: 'Location',
    filterOperators: defaultOperators.location(),
    renderCell: defaultRenderers.location,
    valueFormatter: defaultFormatters.location,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
    // TODO It does not make sense, it is added by the Grid.
    nativeFilter: true,
  },
  {
    field: 'endTime',
    headerName: 'Time to alarm',
    filterable: false,
    renderCell: TimeCell,
  },
  {
    field: 'alertStatus',
    headerName: 'Status',
    renderCell: ({ value }: { value: AmberAlertStatus }) => <AmberAlarmStatusChip status={value} />,
    nativeFilter: 'status',
    filterOperators: AmberAlarmStatusOperator(),
  },
  {
    field: 'activated',
    filterOperators: defaultOperators.date_time(),
    headerName: 'Raised at',
    renderCell: ({ value }: { value: string }) => <StyledCreated>{formatDateForDisplay(value)}</StyledCreated>,
    nativeFilter: true,
  },
] as unknown) as ColumnDefinition[];

const AlarmList: React.FunctionComponent<AmberAlarmListProps> = ({ header, onError }) => {
  const { routing, session } = useStore();
  const refreshRef = React.useRef<(() => void) | null>(null);

  const onRowClick: GridEventListener<'rowClick'> = (alarm: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.admin.users.details, { userId: alarm.row.user.uuid }));
  };

  React.useEffect(() => {
    const events = [EventType.CreatedAmberAlert, EventType.CancelledAmberAlert, EventType.UpdatedAmberAlert];
    const onUpdate = (): void => {
      refreshRef.current?.();
    };
    events.forEach((event) => session.pusherNotifications.addEventListener(event, onUpdate));
    return () => {
      events.forEach((event) => session.pusherNotifications.removeEventListener(event, onUpdate));
    };
  }, []);

  return (
    <Grid
      basicColumns={basicColumns}
      dataURL="/api/alarms/timed/"
      header={header}
      onError={onError}
      onRefreshCallback={(callback) => {
        refreshRef.current = callback;
      }}
      onRowClick={onRowClick}
      sortBy={{ field: 'created', sort: 'desc' }}
      disableColumnSelector
    />
  );
};

export default AlarmList;
