import React from 'react';

import { formatDate } from 'utils/date';

import { Value } from './styles';

const DueDate: React.FunctionComponent<{ date?: string }> = ({ date, ...props }): React.ReactElement => {
  if (date === undefined || date === null) {
    return <Value $color="rgba(0, 0, 0, 0.6)">No Due Date</Value>;
  }
  const { name, color } = formatDate(new Date(date));
  return (
    <Value $color={color} {...props}>
      {name}
    </Value>
  );
};

export default DueDate;
