import React from 'react';

import MainHeader from 'vatix-ui/lib/components/MainHeader';

import Record from 'vatix-ui/lib/containers/Record/Record';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import SitesTable from './components/SitesTable';

const Sites: React.FunctionComponent = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();
  return (
    <Record
      Header={
        <MainHeader
          title="Sites"
          breadcrumbs={[
            { label: 'Sites', link: routes.dashboard.tasks.all.toString() },
            { label: 'Overview', link: routes.dashboard.tasks.all.toString() },
          ]}
        />
      }
      Content={(onError) => <SitesTable onError={onError} />}
      navBarHeight={navBarHeight}
    />
  );
};

export default Sites;
