import styled from 'styled-components';

import vatix from 'theme/vatix';

export const QuestionHeader = styled.div<{ $sectionZero: boolean }>`
  width: 100%;
  background: ${(props) => (props.$sectionZero ? '#fff' : '#f5f5f5')};
  padding: 12px 20px;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

export const QuestionTitle = styled.div`
  color: #101d31;
  font-size: 18px;
  font: normal normal normal 18px/24px Roboto;
  margin: 0;
  align-items: center;
  display: flex;
`;

export const MandatoryQuestion = styled.p`
  color: ${vatix.ui.snackbar.error};
  margin: 0;
  margin-left: 6px;
`;

export const AnswerRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SummaryCounter = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  margin: 0;
  margin-right: 8px;
`;

export const ScoreCounter = styled.div`
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 3px 4px;
  align-items: center;
  height: 24px;
  p {
    color: rgba(0, 0, 0, 0.87);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.16px;
    margin: 0;
    padding: 0px 6px;
  }
`;
