import { observer } from 'mobx-react';
import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import { LocationAddressResponse } from 'vatix-ui/lib/utils/api/types';

import { StyledTextField } from 'components/Input/styles';

import { useStore } from 'utils/hooks/store';

import DetailsInspection from 'stores/FormDetails/DetailsForm';

import CustomLocationTextInput from 'containers/IncidentDetails/components/CustomLocationTextInput';

import { LocationProperties } from 'utils/api/types';

import { StyledSectionGrid, StyledRowTitle, ButtonWrapper } from './styles';

const EditRow: React.FC<{ name: string; disabled?: boolean; children: React.ReactNode; id?: string }> = ({
  name,
  disabled = false,
  children,
  id,
}): React.ReactElement => (
  <>
    <StyledSectionGrid item container xs={12} alignContent="flex-start" id={id} marginTop="16px">
      <StyledRowTitle $disabled={disabled}>{name}</StyledRowTitle>
    </StyledSectionGrid>
    <StyledSectionGrid item container xs={12}>
      {children}
    </StyledSectionGrid>
  </>
);

export const EditOverviewTab: React.FunctionComponent<{ setEditing: (val: boolean) => void }> = ({ setEditing }) => {
  const {
    formDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as DetailsInspection;

  const [name, setName] = React.useState(details.name);
  const [location, setLocation] = React.useState<LocationProperties>({
    address: details.location?.address || '',
    lat: details.location?.lat || '0',
    lng: details.location?.lng || '0',
  });

  const onUpdate = async (): Promise<void> => {
    const res = await details.updateForm(details.uuid, name, (location as unknown) as LocationAddressResponse);
    if (res) {
      setEditing(false);
    }
  };

  return (
    <Grid container style={{ padding: '20px' }}>
      <EditRow name="Name">
        <StyledTextField id="name" name="name" value={name} fullWidth onChange={(e) => setName(e.target.value)} />
      </EditRow>
      <EditRow name="Location" id="location">
        <CustomLocationTextInput
          location={location.address}
          onChange={(e) => setLocation((e as unknown) as LocationProperties)}
          requiredNotFilled={false}
        />
      </EditRow>

      <ButtonWrapper>
        <Button variant="outlined" onClick={() => setEditing(false)} size="large">
          Cancel
        </Button>

        <Button variant="contained" type="submit" size="large" onClick={onUpdate}>
          Save
        </Button>
      </ButtonWrapper>
    </Grid>
  );
};

export default observer(EditOverviewTab);
