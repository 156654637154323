import React from 'react';

import debounce from 'lodash/debounce';

import InputAdornment from '@mui/material/InputAdornment';

import { FormControl, IconButton } from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';

import withGoogleMaps from 'components/Map/withGoogleMaps';

import { LocationAnswerType } from 'utils/api/types';

import { StyledTextField } from 'components/Input/styles';

import Logger from '../../../../utils/logger/Logger';
import { LocationsContainer, LocationItem, LocationText, LocationHighlightText } from './styles';

import { CustomIncidentFieldProps } from './types';
import Location from '../../../../components/Icons/Location';

export const CustomLocationTextInput: React.FunctionComponent<CustomIncidentFieldProps> = ({
  location,
  onChange,
  requiredNotFilled,
}): React.ReactElement => {
  const [options, setOptions] = React.useState<string[]>([]);
  const [debouncedQuery, setDebouncedQuery] = React.useState('');
  const [search, setSearch] = React.useState('');

  const [newAddress, setNewAddress] = React.useState<string | null>(location as string);
  const [newCoordinates, setNewCoordinates] = React.useState<LocationAnswerType | null>(null);
  const [searchActive, setSearchActive] = React.useState(false);

  React.useEffect(() => {
    location && setSearch(location);
    setSearchActive(false);
  }, []);

  React.useEffect(() => {
    if (newAddress === null) {
      onChange(null);
    } else if (newCoordinates) {
      const { lat, lng } = newCoordinates;

      onChange({
        lat: lat.toFixed(9).slice(0, 9),
        lng: lng.toFixed(9).slice(0, 9),
        address: newAddress,
      });
    }
  }, [newAddress, newCoordinates]);

  const setLatLng = (addressString: string): Promise<{ lat: number; lng: number } | null> =>
    new Promise((resolve) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressString }).then(({ results }) => {
        if (results.length > 0) {
          const result = results[0];
          resolve({
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
          });
        } else {
          resolve(null);
        }
      });
    });

  const fetchLocationsGeocoder = React.useCallback(
    async (
      query: string
    ): Promise<{
      data: string[] | null;
      error?: string;
    }> =>
      new Promise((resolve) => {
        try {
          const geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ address: query }, (results) => {
            resolve({
              data: results && results.map((geocoderResult) => geocoderResult.formatted_address),
            });
          });
        } catch (e) {
          Logger.error('Invalid Geocoder API response', e);
          resolve({
            data: null,
            error: 'Unable to load results',
          });
        }
      }),
    []
  );

  const debounced = React.useMemo(() => debounce((newValue: string) => setDebouncedQuery(newValue), 700), []);

  React.useEffect(() => {
    debounced(search);
  }, [search, debounced]);

  React.useEffect(() => {
    (async () => {
      const response = await fetchLocationsGeocoder(debouncedQuery);
      if (response.data !== undefined) {
        response.data && setOptions(response.data);
      }
    })();
  }, [debouncedQuery, fetchLocationsGeocoder]);

  const updateAddress = async (address: string | null): Promise<void> => {
    setNewAddress(address);
  };

  async function updateLocation(address: string | null): Promise<void> {
    address ? setNewCoordinates(await setLatLng(address)) : setNewCoordinates(null);
  }

  const handleUpdate = (address: string): void => {
    updateLocation(address);
    updateAddress(address);
    setOptions([]);
    setSearchActive(false);
    setSearch(address);
  };

  const handleClear = (): void => {
    updateLocation('');
    updateAddress('');
    setSearch('');
    setOptions([]);
    setSearchActive(false);
  };

  const getLocationText = (locationString: string): React.ReactNode[] => {
    let tempLocation = locationString;
    const result = [];
    let nextMatch = tempLocation.toLowerCase().indexOf(search.toLowerCase());

    while (nextMatch !== -1 && search.length > 0) {
      if (nextMatch > 0) {
        result.push(<LocationText>{tempLocation.slice(0, nextMatch).replaceAll(' ', '\u00a0')}</LocationText>);
      }
      result.push(
        <LocationHighlightText>
          {tempLocation.slice(nextMatch, nextMatch + search.length).replaceAll(' ', '\u00a0')}
        </LocationHighlightText>
      );

      tempLocation = tempLocation.slice(nextMatch + search.length);
      nextMatch = tempLocation.toLowerCase().indexOf(search.toLowerCase());
    }

    result.push(<LocationText>{tempLocation.replaceAll(' ', '\u00a0')}</LocationText>);

    return result;
  };

  const handleSearch = (value: string): void => {
    value ? setSearchActive(true) : setSearchActive(false);
    setSearch(value);
  };

  return (
    <FormControl fullWidth>
      <StyledTextField
        error={requiredNotFilled}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Select Location"
        value={search}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!newAddress && <Location />}

              {newAddress && (
                <IconButton data-testid="clear-button" onClick={() => handleClear()}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      <LocationsContainer>
        {options &&
          searchActive &&
          options.map((locationItem) => (
            <LocationItem onClick={() => handleUpdate(locationItem)}>{getLocationText(locationItem)}</LocationItem>
          ))}
      </LocationsContainer>
    </FormControl>
  );
};

export default withGoogleMaps(CustomLocationTextInput);
