import React from 'react';
import { Observer } from 'mobx-react';

import { Button } from 'vatix-ui/lib/components/Button';

import { Formik } from 'formik';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import { useStore } from 'utils/hooks/store';
import {
  InspectionTaskLinkType,
  InspectionTaskLinkToUpdateType,
  TaskListResponse,
  UuidableSubject,
} from 'utils/api/types';

import { formatFullDate } from 'utils/formatters/time';

import CustomDatePicker from 'containers/IncidentDetails/components/CustomDatePicker';

import CustomTextInputField from 'containers/IncidentDetails/components/CustomTextInputField';

import TaskStatusSelect from 'components/TaskStatusSelect';

import CustomUserDropdownField from 'containers/IncidentDetails/components/CustomUserDropdownField';

import { EditTaskRowName } from './styles';
import InspectionField from '../components/InspectionsField';
import QuestionField from '../components/QuestionField/QuestionField';
import IncidentField from '../components/IncidentField/IncidentField';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <div key={id}>
    <EditTaskRowName>{name}</EditTaskRowName>
    <div>{children}</div>
  </div>
);

const EditTaskProperties: React.FunctionComponent<{ setEditing: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setEditing,
}): React.ReactElement => {
  const {
    taskDetails: { details: detailsRaw },
    taskDetails,
  } = useStore();
  const details = detailsRaw as TaskListResponse;

  const { session } = useStore();

  const [taskDetailsData, setTaskDetailsData] = React.useState(details);
  const [dueDate, setDueDate] = React.useState(details.dueDate);

  const updateField = (field: string, value: string | UuidableSubject): void => {
    setTaskDetailsData(
      (prevState) => ({ ...prevState, [field]: value } as Pick<TaskListResponse, keyof TaskListResponse>)
    );
  };

  const onUpdate = async (data: TaskListResponse): Promise<void> => {
    let linksToUpdate: InspectionTaskLinkToUpdateType[] = [];

    if (data.links && data.links.length > 0) {
      const link = data.links[0];

      if (link.extraData) {
        linksToUpdate = [
          {
            entity: 'inspection',
            instance: link.instance.uuid,
            extraData: { questionId: (link.extraData as InspectionTaskLinkType).questionId },
          },
        ];
      } else {
        linksToUpdate = [
          {
            entity: 'inspection',
            instance: link.instance.uuid,
            extraData: {},
          },
        ];
      }
    }

    const updatedData = ({
      ...data,
      links: linksToUpdate,
      dueDate: dueDate || null,
      customIncident: data.customIncident ? data.customIncident.uuid : null,
      assigned: data.assigned ? data.assigned.uuid : null,
    } as unknown) as Partial<TaskListResponse>;

    const res = await taskDetails.updateTask(updatedData);
    setEditing(!res);
  };

  const date = dueDate ? new Date(dueDate) : null;

  return (
    <Formik initialValues={details} onSubmit={onUpdate}>
      {({ isSubmitting }) => (
        <Observer>
          {() => (
            <>
              <Row name="Name" id="field-1">
                <CustomTextInputField
                  description="Name"
                  value={taskDetailsData.name}
                  onChange={(value) => updateField('name', (value as unknown) as string)}
                />
              </Row>
              {session.user?.licenses.protectorIncidents && (
                <IncidentField updateField={updateField} taskDetailsData={taskDetailsData} />
              )}
              {session.user?.licenses.workflows && (
                <InspectionField updateField={updateField} taskDetailsData={taskDetailsData} />
              )}
              {session.user?.licenses.workflows && (
                <QuestionField updateField={updateField} taskDetailsData={taskDetailsData} />
              )}
              <Row name="Description" id="field-3">
                <CustomTextInputField
                  description="Description"
                  value={taskDetailsData.description}
                  onChange={(value) => updateField('description', (value as unknown) as string)}
                  multiline
                />
              </Row>

              <Row name="Assigned to" id="field-4">
                <CustomUserDropdownField
                  description=""
                  value={taskDetailsData.assigned ? `user:${taskDetailsData.assigned.uuid}` : ''}
                  onChange={(value) => {
                    // @ts-ignore
                    updateField('assigned', value ? { uuid: (value as string).split(':')[1] } : null);
                  }}
                />
              </Row>

              <Row id="field-5" name="Status">
                <TaskStatusSelect
                  value={taskDetailsData.status}
                  onChange={(value) => {
                    updateField('status', value);
                  }}
                />
              </Row>

              <Row name="Due date" id="field-6">
                <CustomDatePicker
                  // @ts-ignore
                  value={date || undefined}
                  onChange={(val) => {
                    const convert = val ? formatFullDate(val as string) : '';
                    setDueDate(convert);
                  }}
                  description="Due date"
                />
              </Row>

              <SectionGrid item container xs={12} marginTop="20px" justifyContent="end" marginBottom="20px">
                <Button
                  disabled={isSubmitting}
                  onClick={() => setEditing(false)}
                  style={{
                    marginRight: '16px',
                  }}
                  variant="outlined"
                  size="large"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => {
                    onUpdate(taskDetailsData);
                  }}
                  variant="contained"
                  size="large"
                >
                  Save
                </Button>
              </SectionGrid>
            </>
          )}
        </Observer>
      )}
    </Formik>
  );
};

export default EditTaskProperties;
