import React from 'react';
import { observer } from 'mobx-react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { QuestionAnswer, Container } from './styles';
import { QuestionRowProps } from './types';
import { EmptyAnswerText } from '../styles';

const MultiChoiceAnswer: React.FunctionComponent<QuestionRowProps> = ({ answers }): React.ReactElement =>
  answers !== undefined && answers !== null && answers.length ? (
    <Container>
      {answers.map((answer, index) => (
        <QuestionAnswer key={`${answer}_${index}`}>
          <CheckCircleIcon htmlColor="rgba(7, 114, 229, 1)" />
          <p>{answer}</p>
        </QuestionAnswer>
      ))}
    </Container>
  ) : (
    <EmptyAnswerText>Not answered</EmptyAnswerText>
  );

export default observer(MultiChoiceAnswer);
