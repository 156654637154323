import React from 'react';

import Box from '@mui/material/Box';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';

import { CustomToolbarProps } from './types';
import ToolbarExport from './ToolbarExport';

const CustomToolbar: React.FunctionComponent<CustomToolbarProps> = ({
  setColumnsButtonEl,
  setFilterButtonEl,
  setFilterActive,
  showQuickFilter,
  showHeader,
  dataURL,
  columnsState,
  columnsDefinition,
}) =>
  showHeader ? (
    <GridToolbarContainer
      sx={{
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        minHeight: '76px',
        display: 'flex',
        paddingLeft: '16px',
      }}
    >
      <GridToolbarColumnsButton
        ref={setColumnsButtonEl}
        onClick={() => {
          setFilterActive(false);
        }}
      />
      <GridToolbarFilterButton
        ref={setFilterButtonEl}
        componentsProps={{
          button: {
            onClick: () => {
              setFilterActive(true);
            },
          },
        }}
        enterDelay={0}
      />
      <GridToolbarDensitySelector
        sx={{
          '& .MuiMenuItem-root': {
            paddingRight: '32px',
          },
        }}
      />
      <ToolbarExport url={dataURL} columnsState={columnsState} columnsDefinition={columnsDefinition} />
      <Box sx={{ flex: 1 }} />
      {showQuickFilter ? (
        <GridToolbarQuickFilter
          debounceMs={0}
          quickFilterParser={(input) => input.split(' ')}
          variant="outlined"
          sx={{
            '& .MuiOutlinedInput-root': {
              padding: '3px 6px',
              margin: '15px',
              minHeight: '0',
              minWidth: '320px',
              maxHeight: '36px',
              fontSize: '14px',
              '& .MuiSvgIcon-root': {
                fill: 'black',
                fillOpacity: '0.54',
              },
            },
          }}
        />
      ) : null}
    </GridToolbarContainer>
  ) : null;

export default CustomToolbar;
