import styled from 'styled-components';
import { TextInput } from 'vatix-ui/lib/components/TextInput';

export const StyledTextInput = styled(TextInput)`
  width: 30%;
  height: 90%;
  padding: 8px 12px;
`;

export const Option = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 6px;
  text-align: left;
`;

export const OptionRight = styled(Option)`
  width: 25%;
  margin: 0;
  padding: 8px 2px;
`;
