import { action, observable, runInAction, makeObservable } from 'mobx';
import Pusher, { Channel } from 'pusher-js';
import { eventBus, subscribe } from 'mobx-event-bus2';
import mixpanel from 'mixpanel-browser';

import { ChannelAuthorizationData } from 'pusher-js/types/src/core/auth/options';

import { AxiosResponse } from 'axios';

import routes from 'core/routes';

import { UserProfileResponse } from '../../utils/api/types';
import { EventType } from '../../utils/events/constants';
import { postMessage, channel as broadcastChannel } from '../../utils/events/broadcast';
import { ActionEvent, BroadcastMessage, DeletedUsersPayload } from '../../utils/events/types';
import Logger from '../../utils/logger';
import RootStore from '../Root';
import API from '../../utils/api';
import SessionUser from './SessionUser';
import { PusherConnectionState } from './types';
import { BackendEvent } from './BackendEvent';

export default class SessionStore {
  store: RootStore;

  api: typeof API;

  @observable user: SessionUser | null = null;

  @observable isAuthenticated = false;

  @observable hasWebsocketError = false;

  @observable hasOrganisationLogo = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  socket?: any; // Type is Pusher.Pusher but the typecheck is showing errors. Maybe a mistmach with the types.

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  websocketChannel?: any; // Type is Channel, but the subscribe is showing errors. Maybe a mitchmach with the types.

  pusherNotifications = new EventTarget();

  constructor(rootStore: RootStore, api: typeof API) {
    makeObservable(this);
    this.store = rootStore;
    this.api = api;

    broadcastChannel.onmessage = this.dispatchEvent;

    eventBus.register(this);
  }

  initWebsocket(): void {
    if (!this.user) {
      Logger.error('Unable to init websocket. Missing user');
      return;
    }
    if (!process.env.REACT_APP_PUSHER_KEY) {
      Logger.error('Unable to init websocket. Missing "REACT_APP_PUSHER_KEY" variable');
      return;
    }
    this.socket = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      authorizer: (channel: Channel) => ({
        authorize: async (
          socketId: string,
          callback: (error: Error | null, authData: ChannelAuthorizationData) => void
        ) => {
          try {
            const response = await this.api.authWebsocket(socketId, channel.name)();
            callback(null, response.data);
          } catch (e) {
            Logger.error(`Unable to authenticate websocket. Channel: ${channel}`, e);
            runInAction(() => {
              this.hasWebsocketError = true;
            });
            callback(e as Error, {} as ChannelAuthorizationData);
          }
        },
      }),
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.socket.connection.bind('error', (error: any): void => {
      let code = 'N/A';
      try {
        code = error.data.code;
      } catch (e) {
        Logger.warn('Unable to parse Websocket error code');
      }
      Logger.warn(`Websocket connection error. Error code: ${code}`, error);
    });

    this.socket.connection.bind(
      'state_change',
      (states: { previous: PusherConnectionState; current: PusherConnectionState }): void => {
        runInAction(() => {
          this.hasWebsocketError = states.current !== PusherConnectionState.CONNECTED;
        });
      }
    );

    this.websocketChannel = this.socket.subscribe(`private-${this.user.uuid}`);

    this.websocketChannel.bind('event', this.dispatchEvent);
  }

  @action.bound
  loadOrganisationLogo(): void {
    this.api
      .loadOrganisationLogo()()
      .then((response: AxiosResponse<Blob>) => {
        const objectURL = URL.createObjectURL(response.data);
        if (response.status === 204) {
          this.hasOrganisationLogo = false;
        } else {
          this.hasOrganisationLogo = true;
        }
        const imageElement = document.querySelector('#company-logo') as HTMLImageElement;
        if (imageElement) {
          imageElement.src = objectURL;
        }
      })
      .catch((error: Error) => {
        Logger.error('Invalid update user details API response', error);
      });
  }

  @action.bound
  login(data: UserProfileResponse): void {
    this.user = new SessionUser(data);
    this.isAuthenticated = true;

    postMessage(EventType.LoggedIn, null);

    this.initWebsocket();

    Logger.handler.setUser({
      id: data.uuid,
      email: data.email,
      username: data.name,
    });

    if (data.appCues) {
      window.Appcues.identify(data.uuid, {
        name: data.name,
        email: data.email,
        organisationName: data.organisation.name,
        organisationUUID: data.organisation.uuid,
        role: data.role,
        auditsLicense: data.licenses.workflows,
        loneWorkingLicense: data.licenses.protectorAlarms,
        incidentsLicense: data.licenses.protectorIncidents,
      });
      window.Appcues.track('Logged in');
    }

    if (data.helpScout) {
      window.Beacon('init', 'a9fa5c7f-66d6-4287-8e4e-1c4acc527523');
    }
    mixpanel.identify(data.uuid);
    this.trackMixpanelEvent('Logged in', {
      group_id: data.organisation.uuid,
      group_name: data.organisation.name,
      user_id: data.uuid,
      user_email: data.email,
    });
  }

  dispatchEvent = ({ type, payload }: BroadcastMessage): void => {
    eventBus.post(type, payload);
    this.pusherNotifications.dispatchEvent(new BackendEvent(type, payload));
  };

  @action.bound
  logout(): void {
    mixpanel.reset();
    postMessage(EventType.LoggedOut, null);
  }

  @action.bound
  trackMixpanelEvent(eventName: string, properties: Record<string, unknown>): void {
    if (process.env.JEST_WORKER_ID === undefined) {
      mixpanel.track(eventName, properties);
    }
  }

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isAuthenticated = false;
    this.store.routing.push(routes.login);

    if (this.user) {
      this.socket && this.socket.disconnect();
      eventBus.unregister(this.user);
      this.user = null;
    }
    Logger.handler.setUser(null);
  }

  @subscribe(EventType.DeletedUsers)
  @action
  deletedUsers({ payload }: ActionEvent<DeletedUsersPayload>): void {
    if (this.user && payload.users.includes(this.user.uuid)) {
      this.logout();
    }
  }
}
