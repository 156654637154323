import { CenteredCircularProgress } from 'vatix-ui/lib/components/Progress';
import { UserSearchResponse } from 'vatix-ui/lib/utils/api/types';
import { observer } from 'mobx-react';
import React from 'react';

import { useSearch } from 'vatix-ui/lib/utils/hooks/search';

import { TextField } from '@mui/material';

import API from 'utils/api';

import { useStore } from 'utils/hooks/store';

import { UserRole } from 'core/constants';

import { StyledAutocomplete } from 'components/Input/styles';

import { ProtectorTypeUserProps } from '../types';
import { StyledOption } from './styles';

const loadingOption = { uuid: 'loading', name: 'Loading...' } as UserSearchResponse;

const CustomUserDropdownField = ({
  value,
  onChange,
  requiredNotFilled,
}: ProtectorTypeUserProps): React.ReactElement => {
  const userUUID = value && (value as string).replace('user:', '');
  const { userDetails, session } = useStore();

  const { results: usersList, loading: loadingUsers, searchQuery: searchQueryUsers } = useSearch(
    API.searchUsers,
    undefined,
    { limit: 20 }
  );

  const [selectedUser, setSelectedUser] = React.useState<UserSearchResponse | null | undefined>(undefined);

  React.useEffect(() => {
    if (userUUID) {
      const fetchData = async (): Promise<void> => {
        session.user?.role === UserRole.Manager
          ? await userDetails.loadDetails(userUUID, true)
          : await userDetails.loadDetails(userUUID);

        if (userDetails.isLoaded && userDetails.details) {
          setSelectedUser(userDetails.details as UserSearchResponse);
        }
      };
      fetchData();
    } else {
      setSelectedUser((previous) => previous || null);
    }
  }, [userDetails]);

  return (
    <StyledAutocomplete
      id="user-dropdown-field"
      options={selectedUser === undefined ? [loadingOption] : usersList}
      value={selectedUser === undefined ? loadingOption : selectedUser}
      isOptionEqualToValue={(option: UserSearchResponse | null, val: UserSearchResponse | null) =>
        val?.uuid !== undefined && val?.uuid === option?.uuid
      }
      renderOption={(props, option: UserSearchResponse) =>
        option.uuid === loadingOption.uuid ? (
          <StyledOption>
            <CenteredCircularProgress />
          </StyledOption>
        ) : (
          <StyledOption {...props}>{option.name}</StyledOption>
        )
      }
      getOptionLabel={(option: UserSearchResponse | null) => option?.name as string}
      getOptionDisabled={(option: UserSearchResponse) =>
        option.uuid === loadingOption.uuid || option.uuid === selectedUser?.uuid
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select user"
          value={selectedUser?.name || ''}
          onChange={(e) => {
            searchQueryUsers(e.target.value);
          }}
          error={requiredNotFilled}
        />
      )}
      loading={loadingUsers}
      noOptionsText="-"
      onChange={(_, rawValue: UserSearchResponse | null) => {
        setSelectedUser(rawValue);
        onChange(rawValue && `user:${rawValue.uuid}`);
      }}
      fullWidth
      blurOnSelect
    />
  );
};

export default observer(CustomUserDropdownField);
