import React from 'react';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { CircularProgress } from '@mui/material';

import User from 'vatix-ui/lib/components/Properties/User';

import { useStore } from 'utils/hooks/store';
import { SessionStoreWithUser } from 'stores/Session/types';
import { TaskStatus } from 'core/constants';

import API from 'utils/api/api';

import TaskStatusSelect from 'components/TaskStatusSelect';

import { TaskListItemProps } from './types';
import { TaskName, StyledTextInput, ItemContainer, SpinnerContainer } from './styles';

const TaskListItem: React.FunctionComponent<TaskListItemProps> = ({
  store,
  task: { uuid, assigned, name, status },
}): React.ReactElement => {
  const { session: sessionRaw } = useStore();
  const session = sessionRaw as SessionStoreWithUser;
  const [editing, setEditing] = React.useState(uuid === '');
  const [newName, setNewName] = React.useState(name);
  const [loading, setLoading] = React.useState(false);

  const onEnter = async (): Promise<void> => {
    if (newName === '') {
      onExit();
    } else {
      setEditing(false);
      store.setEditingTask(false);
      setLoading(true);
      if (uuid === '') {
        await store.createTaskAnswerTask(newName);
      } else if (newName !== name && newName !== `${name}\n`) {
        await store.updateTask(uuid, { name: newName });
      }
      setLoading(false);
    }
  };

  const onExit = (): void => {
    store.removeGhostTask();
    setEditing(false);
    store.setEditingTask(false);
  };

  const onEdit = (event: React.MouseEvent<HTMLParagraphElement>): void => {
    event.stopPropagation();
    setNewName(name);
    setEditing(true);
    store.setEditingTask(true);
  };

  const onUpdateStatus = async (newStatus: TaskStatus): Promise<void> => {
    await store.updateTask(uuid, { status: newStatus });
  };

  const onUpdateUser = async (user: UuidableName | null): Promise<void> => {
    await store.updateTask(uuid, { assigned: user ? user.uuid : null });
  };

  return loading ? (
    <SpinnerContainer>
      <CircularProgress size="3rem" />
    </SpinnerContainer>
  ) : (
    <>
      <ItemContainer>
        {editing ? (
          <StyledTextInput
            autoFocus
            onBlur={onEnter}
            onClick={(event) => {
              event.stopPropagation();
              setEditing(true);
            }}
            onChange={(e) => {
              setNewName(e);
              setEditing(true);
            }}
            onEnter={onEnter}
            onEscape={onExit}
            value={newName}
            id="name-input"
          />
        ) : (
          <TaskName onClick={onEdit} id="task-name">
            {newName}
          </TaskName>
        )}
      </ItemContainer>
      <ItemContainer>
        <User onUpdateUser={onUpdateUser} searchAPI={API.searchUsers} sessionUser={session.user} user={assigned} />
      </ItemContainer>
      <ItemContainer onClick={(e) => e.stopPropagation()}>
        <TaskStatusSelect onChange={onUpdateStatus} value={status} withoutBorder disableIconComponent />
      </ItemContainer>
    </>
  );
};

export default TaskListItem;
