import { observer } from 'mobx-react';
import React from 'react';

import { AnswerImage } from './styles';
import { QuestionRowProps } from './types';
import { EmptyAnswerText } from '../styles';

const SignatureAnswer: React.FunctionComponent<QuestionRowProps> = ({ answer }): React.ReactElement => (
  <>
    {answer?.answer ? (
      <AnswerImage alt="media answer" src={answer.answer as string} />
    ) : (
      <EmptyAnswerText>Not answered</EmptyAnswerText>
    )}
  </>
);

export default observer(SignatureAnswer);
