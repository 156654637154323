import { observer } from 'mobx-react';
import React from 'react';

import { Grid } from '@mui/material';

import { Button } from 'vatix-ui/lib/components/Button';

import EditIcon from '@mui/icons-material/Edit';

import LocationOnIcon from '@mui/icons-material/LocationOn';

import { SectionGrid } from 'components/BackgroundInformation/styles';

import DetailsInspection from 'stores/FormDetails/DetailsForm';
import LinkedTasksCell from 'containers/Incidents/components/LinkedTasksCell';
import { LinkedTaskType, LocationProperties } from 'utils/api/types';
import { formatDateForDisplay } from 'utils/formatters/time';
import UserField from 'containers/IncidentDetails/components/UserField';
import LocationOnFilled from 'components/Icons/LocationOnFilled';
import PersonalMap from 'containers/UserDetails/components/PersonalMap';

import { ScoreChip } from 'containers/FormsDetails/styles';

import { ScoreChipColors } from 'containers/FormsDetails/types';

import { StyledRowTitle, RowValue, TabTitle, LocationContainer } from './styles';
import { useStore } from '../../../../utils/hooks/store';
import EditOverviewTab from './EditOverviewTab';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <>
    <SectionGrid item container xs={2} alignContent="flex-start" id={id} margin="8px 0">
      <StyledRowTitle>{name}</StyledRowTitle>
    </SectionGrid>
    <SectionGrid item container xs={10}>
      {children}
    </SectionGrid>
  </>
);

export const OverviewTab: React.FunctionComponent = () => {
  const {
    formDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as DetailsInspection;
  const [editing, setEditing] = React.useState(false);
  const [showMap, setShowMap] = React.useState(false);

  return (
    <div style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
      <Grid
        container
        style={{ padding: '8px 20px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
        alignItems="center"
      >
        <SectionGrid item container xs={10} alignContent="flex-start">
          <TabTitle>Form Details</TabTitle>
        </SectionGrid>
        <SectionGrid item container xs={2} justifyContent="end">
          {!editing && (
            <Button size="medium" variant="text" onClick={() => setEditing(true)}>
              <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          )}
        </SectionGrid>
      </Grid>
      {editing ? (
        <EditOverviewTab setEditing={setEditing} />
      ) : (
        <Grid container marginTop="16px" style={{ padding: '0px 20px 20px 20px' }} alignItems="center">
          <Row name="Name">
            <RowValue id="form-details-name-value">{details.name || 'N/A'}</RowValue>
          </Row>
          {details && details.scores && details.scores?.allPoints !== '0' ? (
            <Row name="Overall Score">
              <RowValue>
                <ScoreChip $bgColor={details?.scores?.bgColor || ScoreChipColors.blue}>
                  {details?.scores?.average}
                </ScoreChip>
              </RowValue>
            </Row>
          ) : null}

          <Row name="Template">
            <RowValue id="form-details-template-name-value">{details.template.name || 'N/A'}</RowValue>
          </Row>

          <Row name="Reporter">
            <UserField userId={details?.reporter?.uuid as string} />
          </Row>

          <Row name="Linked Actions">
            <LinkedTasksCell linkedTasks={details?.linkedTasks as LinkedTaskType} />
          </Row>

          <Row name="Submitted">
            <RowValue id="form-details-submitted-value">{formatDateForDisplay(details.submitted) || 'N/A'}</RowValue>
          </Row>

          <Row name="Location">
            {details.location ? (
              <LocationContainer>
                <LocationOnFilled />
                <RowValue id="alarm-location-value">{details.location?.address}</RowValue>
              </LocationContainer>
            ) : (
              <RowValue>N/A</RowValue>
            )}
          </Row>
          <Row name="">
            {details.location?.address &&
              ((details.location as unknown) as LocationProperties).lat &&
              ((details.location as unknown) as LocationProperties).lng && (
                <>
                  <Button
                    id="alarm-map-button"
                    onClick={() => {
                      setShowMap((prev) => !prev);
                    }}
                  >
                    {showMap ? 'Hide Map' : 'Show Map'}
                  </Button>
                  <div style={{ width: '100%' }} id="form-details-map">
                    {showMap && (
                      <PersonalMap
                        position={{
                          lat: Number(((details.location as unknown) as LocationProperties).lat),
                          lng: Number(((details.location as unknown) as LocationProperties).lng),
                        }}
                        icon={<LocationOnIcon htmlColor="#0772E5" fontSize="large" />}
                        mapStyle={{ minHeight: 320 }}
                        roundColor="none"
                      />
                    )}
                  </div>
                </>
              )}
          </Row>
        </Grid>
      )}
    </div>
  );
};

export default observer(OverviewTab);
