import React from 'react';

import { OptionRight, StyledTextInput } from './styles';

type ScoreFieldType = {
  index: number;
  scoring: string[];
  setScoring: React.Dispatch<React.SetStateAction<string[] | null>>;
};

const ScoreField: React.FunctionComponent<ScoreFieldType> = ({ index, scoring, setScoring }) => {
  const initialScoring = scoring[index] || '0';
  const [score, setScore] = React.useState(initialScoring);
  const [editScore, setEditScore] = React.useState(false);

  const getValidatedScore = (): string => {
    try {
      const numberScoreValue = Number(score);
      if (numberScoreValue < 0) {
        throw new Error('Negatives not allowed');
      }
      return String(numberScoreValue);
    } catch (ex) {
      return initialScoring;
    }
  };

  const updateScore = (): void => {
    setScore(getValidatedScore());
    setScoring((state) => {
      const newTemp = [...(state as string[])];
      newTemp[index] = getValidatedScore();
      return newTemp;
    });
  };

  const handleSubmit = (): void => {
    setEditScore(false);
    updateScore();
  };

  React.useLayoutEffect(() => {
    if (scoring[index] === undefined) {
      updateScore();
    }
  }, []);

  return editScore ? (
    <StyledTextInput
      autoFocus
      value={score}
      onChange={(event) => {
        event.stopPropagation();
        setScore(event.target.value);
      }}
      onKeyDown={(event) => {
        if (event.key === 'e' || event.key === '-' || event.key === '+') {
          event.preventDefault();
        } else if (event.key === 'Enter') {
          handleSubmit();
        }
        event.stopPropagation();
      }}
      type="number"
      min="0"
      data-testid="edit-score-text-input"
      onBlur={handleSubmit}
    />
  ) : (
    <OptionRight
      onClick={() => {
        setEditScore(true);
      }}
    >
      {scoring[index]}
    </OptionRight>
  );
};

export default ScoreField;
