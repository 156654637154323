import React from 'react';

import { GridValueGetterParams } from '@mui/x-data-grid';

import Grid, { defaultRenderers } from 'components/Grid';
import { ColumnDefinition } from 'components/Grid/types';

import { Site } from 'containers/Sites/types';

import { LocationProperties } from 'utils/api/types';

import ActionCell from '../ActionCell';

export const basicColumns = ([
  {
    field: 'siteName',
    headerName: 'Name',
  },
  {
    field: 'siteId',
    headerName: 'Id',
  },
  {
    field: 'siteType',
    headerName: 'Type',
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: defaultRenderers.status,
  },
  {
    field: 'location',
    headerName: 'Location',
    renderCell: ({ value }: { value: LocationProperties }) => <p>{value.address}</p>,
  },
  {
    field: 'siteManager',
    headerName: 'Manager',
    renderCell: defaultRenderers.user,
    valueGetter: ({ value }: GridValueGetterParams) => ({ value }),
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: '',
    maxWidth: 50,
    ariaLabel: 'actions_cell',
    renderCell: ({ row, colDef }: { row: Site; colDef: ColumnDefinition }) => (
      <ActionCell rowData={row} actions={colDef.actions} />
    ),
    filterOperators: null,
  },
] as unknown) as ColumnDefinition[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SitesTable: React.FunctionComponent<{ onError: (error: any) => void }> = ({ onError }): React.ReactElement => (
  <Grid
    basicColumns={basicColumns}
    entity="action"
    onError={onError}
    dataURL="/api/entities/sites/"
    customNoRowsText="No sites have been created yet"
    showHeader={false}
    showQuickFilter={false}
  />
);

export default SitesTable;
