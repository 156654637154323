import React from 'react';

import { observer } from 'mobx-react';

import AddCommentActivities from 'vatix-ui/lib/components/AddCommentActivities';

import Feed from 'vatix-ui/lib/components/Feed';

import { UuidableName } from 'vatix-ui/lib/utils/api/types';

import { reverse } from 'named-urls';

import { useHistory } from 'react-router-dom';

import { DefaultFeedMapping } from 'vatix-ui/lib/components/Feed/Feed';

import { FeedProps } from 'vatix-ui/lib/components/Feed/types';

import { useStore } from 'utils/hooks/store';

import API from 'utils/api/api';

import { QuestionType } from 'stores/TemplateDetails/types';

import AnswerDetails from 'stores/FormDetails/AnswerDetails';

import AnswerTasks from 'stores/FormDetails/AnswerTasks';

import { getAnswerComponent } from 'containers/FormsDetails/utils';

import routes from 'core/routes';

import RootStore from 'stores/Root';

import { ActivityType } from 'utils/stores/Activities/constants';

import {
  HeaderContainer,
  CloseLink,
  DefaultContainer,
  ScrollableContainer,
  QuestionContainer,
  QuestionDescription,
} from './styles';

import TaskList from '../TaskList';
import { EmptyAnswerText } from '../styles';
import { QuestionFeedPanelProps } from './types';
import Assignment from './Assignment';
import State from './State';
import Status from './Status';

const activityTypeToComponent = ({
  ...DefaultFeedMapping,
  [ActivityType.Assignment]: Assignment,
  [ActivityType.State]: State,
  [ActivityType.Status]: Status,
} as unknown) as FeedProps<RootStore, typeof API>['mapping'];

const QuestionDrawer: React.FunctionComponent<QuestionFeedPanelProps> = ({
  questionId,
  sectionId,
  onClose,
}): React.ReactElement => {
  const { formDetails, session } = useStore();

  const history = useHistory();
  const onTaskClick = (taskId: string): void => {
    history.push(reverse(routes.dashboard.tasks.details, { taskId }));
  };

  const [keepBottom, setKeepBottom] = React.useState(true);
  const scrollableRef = React.useRef<HTMLDivElement | null>(null);

  const sectionID = formDetails.details?.template.content.order.indexOf(sectionId) as number;
  const answer = formDetails.details?.answers[sectionID][questionId] as AnswerDetails;

  const onScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    // @ts-ignore
    const bottom = e.target.clientHeight;
    // @ts-ignore
    const scrolledAt = e.target.scrollHeight - e.target.scrollTop;
    const shouldKeepBottom = scrolledAt - bottom <= 20; // 20 is the padding.

    if (shouldKeepBottom !== keepBottom) {
      setKeepBottom(shouldKeepBottom);
    }
  };

  const questionProperties = formDetails.details?.template.content.properties[sectionId].properties[
    questionId
  ] as QuestionType;
  const position = formDetails.details?.questionIds.indexOf(questionId) as number;

  return (
    <>
      <HeaderContainer>
        <CloseLink onClick={onClose} id="close-details-link">
          X Close Details
        </CloseLink>
        <QuestionContainer>
          <h1>{`QUESTION ${position + 1}`}</h1>
          <QuestionDescription>{questionProperties.description}</QuestionDescription>
          {questionProperties ? (
            getAnswerComponent(questionProperties.workflowsType || questionProperties.type, answer, questionProperties)
          ) : (
            <EmptyAnswerText>Not answered</EmptyAnswerText>
          )}
        </QuestionContainer>
      </HeaderContainer>

      <DefaultContainer>
        <TaskList onSelected={(task) => onTaskClick(task.uuid)} tasks={answer?.tasks as AnswerTasks} />
      </DefaultContainer>

      <ScrollableContainer onScroll={onScroll} ref={scrollableRef}>
        <Feed
          originalActivities={answer?.activities}
          onDeleteComment={answer?.activities.deleteMessage}
          onDeleteFile={answer?.activities.deleteFile}
          usersSearch={API.searchUsers}
          sessionUser={session.user as UuidableName}
          downloadFile={API.downloadFile}
          mapping={activityTypeToComponent}
        />
      </ScrollableContainer>
      <DefaultContainer>
        <AddCommentActivities
          placeholder="Post an update"
          sendMessage={answer?.activities.addMessage as (message: string) => Promise<void>}
          uploadFile={answer?.activities.uploadFile as (file: File) => Promise<void>}
          usersSearch={API.searchUsers}
        />
      </DefaultContainer>
    </>
  );
};

export default observer(QuestionDrawer);
