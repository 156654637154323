import { observer } from 'mobx-react';
import React from 'react';

import { Drawer } from '@mui/material';

import { useLocation, useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import DetailsInspection from 'stores/FormDetails/DetailsForm';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { QuestionsContainer } from './styles';
import CollapsedSections from './CollapsedSections';
import QuestionDrawer from './QuestionDrawer';
import QuestionRow from './QuestionRow';
import { QuestionContainer } from './CollapsedSections/styles';

function useQuery(): URLSearchParams {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const FormTab: React.FunctionComponent = () => {
  const {
    formDetails: { details: detailsRaw },
  } = useStore();
  const details = detailsRaw as DetailsInspection;

  const query = useQuery();
  const history = useHistory();
  const questionId = query.get('questionId');

  const goToQuestion = (qId: string): void => {
    const route = reverse(routes.dashboard.audits.forms.details, {
      formId: details.uuid,
    });
    history.push(`${route}?questionId=${qId}`);
  };

  const onCloseDrawer = (): void => {
    history.push(
      reverse(routes.dashboard.audits.forms.details, {
        formId: details.uuid,
      })
    );
  };

  return (
    <QuestionsContainer>
      {(details?.template.content.order || []).map((sectionId) => (
        <>
          {sectionId !== '0' ? (
            <CollapsedSections
              goToQuestion={(qId) => goToQuestion(qId)}
              sectionId={sectionId}
              questionId={questionId || ''}
              key={`${sectionId}_section`}
            />
          ) : (
            <>
              {details?.template.content.properties[sectionId].order.map((questionIdItem) => (
                <div key={`${questionIdItem}_question`}>
                  <QuestionContainer
                    onClick={() => goToQuestion(questionIdItem)}
                    selected={questionIdItem === questionId}
                    $withoutSection
                  >
                    <QuestionRow questionId={questionIdItem} showStats sectionId={sectionId} />
                  </QuestionContainer>
                </div>
              ))}
            </>
          )}
        </>
      ))}
      <Drawer
        anchor="right"
        open={!!questionId}
        onClose={onCloseDrawer}
        PaperProps={{ sx: { minWidth: '490px', maxWidth: '490px' } }}
        key={`${questionId}_drawer`}
      >
        {questionId && (
          <QuestionDrawer
            questionId={questionId || ''}
            sectionId={questionId.split('::')[0] || ''}
            onClose={onCloseDrawer}
          />
        )}
      </Drawer>
    </QuestionsContainer>
  );
};

export default observer(FormTab);
