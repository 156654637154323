import styled from 'styled-components';
import Link from '@mui/material/Link';

export const DefaultContainer = styled.div`
  padding: 21px 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e2e4e6;
`;

export const HeaderContainer = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e2e4e6;
`;

export const QuestionContainer = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  background: #f5f5f5;

  h1 {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 266%; /* 31.92px */
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const QuestionDescription = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
  letter-spacing: 0.15px;
  margin: 0;
  margin-bottom: 8px;
`;

export const CloseLink = styled(Link)`
  color: #0772e5;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: 0.46px;
  text-decoration: none;
  margin-bottom: 10px;
`;

export const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 0;
  flex-grow: 1;
  margin: 0 20px;
`;
