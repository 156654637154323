import React from 'react';

import Record from 'vatix-ui/lib/containers/Record/Record';

import { MainHeader } from 'vatix-ui/lib/components/MainHeader/MainHeader';

import routes from 'core/routes';
import { useNavBarHeight } from 'utils/hooks/navbar';

import FormsList from './components/FormsList/FormsList';

const Forms = (): React.ReactElement => {
  const navBarHeight = useNavBarHeight();

  return (
    <Record
      navBarHeight={navBarHeight}
      Content={(onError) => <FormsList onError={onError} />}
      Header={
        <MainHeader
          title="Forms"
          breadcrumbs={[
            { label: 'Audits', link: routes.dashboard.audits.forms.toString() },
            { label: 'Forms', link: routes.dashboard.audits.forms.toString() },
          ]}
        />
      }
    />
  );
};

export default Forms;
