import React from 'react';

import { useHistory } from 'react-router-dom';

import { reverse } from 'named-urls';

import { Button } from 'vatix-ui/lib/components/Button';

import EditIcon from '@mui/icons-material/Edit';

import { Grid } from '@mui/material';

import { InspectionTaskLinkType, TaskListResponse } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import UserRow from 'components/UserRow';

import routes from 'core/routes';

import EditTaskProperties from 'containers/TaskDetails/EditTaskProperties';

import { UserRole } from 'core/constants';

import { userPermissionForIncident } from 'utils/userPermissionForIncident';

import SessionUser from 'stores/Session/SessionUser';

import FormIcon from 'components/Icons/FormIcon';

import QuestionIcon from 'components/Icons/QuestionIcon';

import {
  StyledName,
  DescriptionField,
  RelatedToIncidentValue,
  StyledRedirect,
  TaskRecordContainer,
  TaskRecordRow,
  RecordRow,
  FirstColumn,
  SecondColumn,
  Title,
  HeaderGrid,
} from './styles';

import TaskDueDate from '../TaskDueDate';
import TaskStatusChip from '../TaskStatusChip';

const Row: React.FC<{ name: string; children: React.ReactNode; id?: string }> = ({
  name,
  children,
  id,
}): React.ReactElement => (
  <TaskRecordRow>
    <RecordRow key={id}>
      <FirstColumn>{name}</FirstColumn>
      <SecondColumn>{children}</SecondColumn>
    </RecordRow>
  </TaskRecordRow>
);

const TaskProperties: React.FunctionComponent = (): React.ReactElement => {
  const { taskDetails, session, notification } = useStore();
  const details = taskDetails.details as TaskListResponse;
  const history = useHistory();

  const [editing, setEditing] = React.useState<boolean>(false);

  const navigateToIncident = (event: React.MouseEvent): void => {
    history.push(reverse(routes.dashboard.incidents.details, { incidentId: details.customIncident?.uuid as string }));
    event.stopPropagation();
  };

  const navigateToForm = (event: React.MouseEvent): void => {
    if (session.user?.licenses.workflows) {
      const {
        instance: { uuid: instanceUuid },
      } = details.links[0];
      history.push(reverse(routes.dashboard.audits.forms.details, { formId: instanceUuid as string }));
      event.stopPropagation();
    }
  };

  const navigateToQuestion = (event: React.MouseEvent): void => {
    if (session.user?.licenses.workflows) {
      const {
        instance: { uuid: instanceUuid },
        // @ts-ignore
        extraData: { questionId },
      } = details.links[0];
      const route = reverse(routes.dashboard.audits.forms.details, {
        formId: instanceUuid as string,
      });
      history.push(`${route}?questionId=${questionId}`);
      event.stopPropagation();
    }
  };

  return (
    <>
      <HeaderGrid container alignItems="center">
        <Grid item container xs={10} alignContent="flex-start">
          <Title>Task Details</Title>
        </Grid>
        <Grid item container xs={2} justifyContent="end">
          {!editing && (
            <Button
              size="small"
              variant="text"
              onClick={() => setEditing((prevState) => !prevState)}
              style={{ padding: 0, fontSize: '14px' }}
            >
              <EditIcon style={{ width: 20, height: 20, marginRight: '8px' }} />
              Edit
            </Button>
          )}
        </Grid>
      </HeaderGrid>
      <TaskRecordContainer $editing={editing}>
        {editing ? (
          <EditTaskProperties setEditing={setEditing} />
        ) : (
          <>
            <Row name="Name" id="task-name-value">
              <StyledName id="task-name-value">{details.name}</StyledName>
            </Row>
            <Row name="Incident" id="related-to-label">
              {details.customIncident ? (
                <StyledRedirect
                  onClick={(e) => {
                    if (
                      userPermissionForIncident(details.customIncident, session.user as SessionUser) ||
                      session.user?.role === UserRole.Manager ||
                      session.user?.role === UserRole.Admin ||
                      session.user?.role === UserRole.AccountManager
                    ) {
                      navigateToIncident(e);
                    } else {
                      notification.enqueueErrorSnackbar(`You don't have permission to see this incident`);
                    }
                  }}
                  role="link"
                  $isClickable={session.user?.licenses.protectorIncidents || false}
                >
                  <RelatedToIncidentValue id="incident-related-to-incident">
                    {details.customIncident.subject}
                  </RelatedToIncidentValue>
                </StyledRedirect>
              ) : (
                '-'
              )}
            </Row>

            <Row name="Form" id="related-to-label">
              {details.links.length > 0 ? (
                <>
                  <StyledRedirect
                    onClick={navigateToForm}
                    role="link"
                    style={{
                      marginBottom: '6px',
                    }}
                    $isClickable={session.user?.licenses.workflows || false}
                  >
                    <FormIcon />
                    <RelatedToIncidentValue id="incident-related-to-incident">
                      {details.links[0].instance.name}
                    </RelatedToIncidentValue>
                  </StyledRedirect>

                  {details.links[0].extraData ? (
                    <StyledRedirect
                      onClick={navigateToQuestion}
                      role="link"
                      $isClickable={session.user?.licenses.workflows || false}
                    >
                      <QuestionIcon />
                      <RelatedToIncidentValue id="incident-related-to-incident">
                        {(details.links[0].extraData as InspectionTaskLinkType).question}
                      </RelatedToIncidentValue>
                    </StyledRedirect>
                  ) : null}
                </>
              ) : (
                '-'
              )}
            </Row>

            <Row name="Description" id="description-label">
              {details.description ? (
                <DescriptionField id="task-description">
                  {details.description.split('\n').map((str, index) => (
                    <p key={`${str}_${index}`}>{str}</p>
                  ))}
                </DescriptionField>
              ) : (
                '-'
              )}
            </Row>
            <Row name="Assigned to" id="assigned-to-label">
              {details.assigned ? <UserRow user={details.assigned} /> : '-'}
            </Row>
            <Row name="Status" id="status-label">
              <TaskStatusChip status={details.status} />
            </Row>
            <Row name="Due date" id="due-date-label">
              {details.dueDate ? <TaskDueDate date={details.dueDate} /> : '-'}
            </Row>
          </>
        )}
      </TaskRecordContainer>
    </>
  );
};

export default TaskProperties;
